<template>
  <CreateEventForm class="px-3" />
</template>

<script>
import CreateEventForm from '@/components/CreateEventForm.vue';

export default {
  name: 'CreateEventPage',
  components: {
    CreateEventForm,
  },
  data() {
    return {};
  },
  async mounted() {},
};
</script>

<style lang="scss">
// TODO: overview these styles - maybe move them to page-wrapper
// .create-event-page {
//   height: calc(
//     100dvh - 140px - 12px - env(safe-area-inset-bottom) -
//       env(safe-area-inset-top)
//   );
//   overflow-y: scroll;
// }
</style>
