<template>
  <div class="identity-form">
    <div class="description">
      <h1>{{ $t('identityFormTitles.aboutYou') }}</h1>
      <h3>{{ $t('identityFormTitles.descriptionLine1') }}</h3>
      <h3>{{ $t('identityFormTitles.descriptionLine2') }}</h3>
      <h3>{{ $t('identityFormTitles.descriptionLine3') }}</h3>
    </div>

    <div class="identity-info">
      <div class="element-wrapper">
        <BaseDatePicker v-model="dateOfBirth" inputLabel="label.dateOfBirth" append-inner-icon="mdi-calendar" />
      </div>

      <div class="element-wrapper">
        <BaseSelect outerLabel="label.identify" :items="identities" v-model="identity" />
      </div>

      <div class="element-wrapper">
        <BaseAutocompleteSelect outerLabel="label.countryRegion" v-model="country" :items="countriesList" />
      </div>

      <div class="element-wrapper">
        <!-- add google autocomplete for city -->
        <BaseInput inputLabel="label.city" v-model="locationText" placeholder="placeholder.city"
          @input="debouncedLocationHandler($event, googleAutocompleteOptions)" />
        <div class="location-predictions" v-if="locationPredictions.length">
          <div class="prediction py-1 px-2" v-for="prediction in locationPredictions" :key="prediction.place_id"
            @click="selectLocation(prediction, true)">
            {{ prediction.description }}
          </div>
        </div>
      </div>

      <div class="element-wrapper d-flex terms-policy-wrapper">
        <v-checkbox v-model="isAgreementChecked" hide-details="" color="#a594fd" class="checkbox"
          @change="agreementChangeHandler"></v-checkbox>
        <div class="terms-and-privacy">
          <span class="terms-text">{{ $t('label.agreeToTermsOfUse')
            }}<a class="link" :href="termsOfUse" target="_blank">{{
              $t('label.termsOfUseLink')
            }}</a>
            {{ $t('label.and') }}
            <a class="link" :href="privacyPolicy" target="_blank">{{
              $t('label.privacyPolicyLink')
            }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseAutocompleteSelect from '@/components/base/BaseAutocompleteSelect.vue';
import { privacyPolicy, termsOfUse } from '@/constants/urls';
import locationInputMixin from '@/mixins/locationInputMixin';

export default {
  name: 'IdentityForm',
  components: {
    BaseInput,
    BaseSelect,
    BaseDatePicker,
    BaseAutocompleteSelect,
  },
  mixins: [locationInputMixin],
  data() {
    return {
      dateOfBirth: new Date(),
      privacyPolicy,
      termsOfUse,
      country: '',
      city: '',
      identity: '',
      place_id: '',
      identities: [
        this.$t('identities.male'),
        this.$t('identities.female'),
        this.$t('identities.nonBinary'),
        this.$t('identities.other'),
      ],
      isAgreementChecked: false,
    };
  },
  watch: {
    locationText(value) {
      this.city = value.split(',')[0];
      this.place_id = this.place_id;
    },
  },
  methods: {
    agreementChangeHandler() {
      this.$emit('dataChange', {
        type: 'termsAndPolicy',
        value: this.isAgreementChecked,
      });
    },
  },
  computed: {
    newUserData() {
      return {
        dateOfBirth: this.dateOfBirth,
        country: this.country,
        city: this.city,
        identity: this.identity,
        place_id: this.place_id,
      };
    },
    countriesList() {
      return this.$store.getters['SearchStore/getCountriesList'].map((c) => c.native);
    },
    googleAutocompleteOptions() {
      const userCountry = this.$store.getters['UserStore/getCountry'];
      const countriesList = this.$store.getters['SearchStore/getCountriesList'];
      const countryIso2 = countriesList.find(
        (country) => country.name === userCountry || country.native === userCountry
      )?.iso2 || '';

      if (!countryIso2) {
        return {
          types: ['(cities)'],
        };
      }

      return {
        componentRestrictions: { country: countryIso2 },
        types: ['(cities)'],
      };
    },
  },
};
</script>

<style lang="scss">
.identity-form {
  .description {
    text-align: center;
    margin-bottom: 52px;
  }

  .location-predictions {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    overflow-y: auto;
  }

  .v-input {
    margin-bottom: 12px;
  }

  .terms-policy-wrapper {
    margin-top: 24px;

    .terms-and-privacy {
      display: flex;
      align-items: center;
      width: 90%;

      .link {
        color: #007bff;
        text-decoration: none;
        margin-inline-start: 2px;
      }

      .terms-text {
        height: 56px;
      }
    }

    .checkbox {
      margin-inline-end: 6px;
      margin-inline-start: -6px;
    }
  }
}
</style>
