import QrCode from 'qrcode';

// const qrCanvas = document.createElement('canvas');
// qrCanvas.id = 'qrCanvas';

export default {
  async generateQrCode(text) {
    try {
      const qrCode = await QrCode.toDataURL(text);
      return qrCode;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};
