<template>
  <v-btn class="text-none base-button" variant="flat">
    <img v-if="icon" :src="getImgUrl" alt="" class="button-icon" />
    <slot />
  </v-btn>
</template>
<script>
//TODO add loading styles and props
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    getImgUrl() {
      return this.icon ? require(`@/assets/images/${this.icon}.svg`) : '';
    },
  },
};
</script>

<style lang="scss">
.base-button {
  &.v-btn.v-btn--density-default {
    height: 40px;
    width: 100%;
  }
}

button.base-button {
  background: rgb(var(--v-theme-background));
  border: 1px solid rgb(var(--v-theme-primary));
  border-radius: 8px;
  align-items: center;

  text-transform: none;
  color: rgb(var(--v-theme-primary));
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  img {
    height: 24px;
    width: 24px;
    margin-inline-end: 8px;
    fill: rgb(var(--v-theme-primary));
  }

  &.v-btn--disabled {
    border: 1px solid rgb(var(--v-theme-disabled));
  }
}

button.primary {
  background: rgb(var(--v-theme-primary));
  color: white;
}

button.secondary {
  background: rgb(var(--v-theme-primary));
}

button.cancel {
  color: rgb(var(--v-theme-error));
  border: 1px solid rgb(var(--v-theme-error));
}

button.red {
  border: 1px solid rgb(var(--v-theme-error));
  background: transparent;

  .v-btn__content {
    color: rgb(var(--v-theme-error));
  }
}
</style>
