import { io } from 'socket.io-client';
const serverUrl = process.env.VUE_APP_SOCKET_SERVER_URL;
const socketIO = io(`${serverUrl}`, {
  withCredentials: true,
  reconnection: true,
});

socketIO.on('connect', () => {
  console.log('socket connected');
});

socketIO.connect();

export default socketIO;
