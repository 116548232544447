<template>
  <div class="settings-page">
    <div class="links-list">
      <div class="link" @click="changeRoute('securitySettings')">
        <div class="group">
          <ShieldSvg />
          <div class="section-label">{{ $t('label.passwordAndSecurity') }}</div>
        </div>
        <ChevronSvg />
      </div>
      <div class="link" @click="changeRoute('socialSettings')">
        <div class="group">
          <CommunitySvg />
          <div class="section-label">{{ $t('label.socials') }}</div>
        </div>
        <ChevronSvg />
      </div>
      <div class="link" @click="changeRoute('notificationsSettings')">
        <div class="group">
          <BellSvg />
          <div class="section-label">{{ $t('label.notifications') }}</div>
        </div>
        <ChevronSvg />
      </div>
      <div class="link" @click="changeRoute('premiumSettings')" v-if="false">
        <div class="group">
          <PremiumSvg />
          <div class="section-label">{{ $t('label.premium') }}</div>
        </div>
        <ChevronSvg />
      </div>
      <div class="link" @click="openPrivacyPolicy">
        <div class="group">
          <InfoSvg />
          <div class="section-label">{{ $t('label.privacyPolicy') }}</div>
        </div>
        <ChevronSvg />
      </div>
      <div class="link disabled" v-if="false">
        <div class="group">
          <StarSvg />
          <div class="section-label">{{ $t('label.aboutRating') }}</div>
        </div>
        <ChevronSvg />
      </div>
      <div class="link" @click="changeRoute('blockedUsers')">
        <div class="group">
          <BlockedSvg />
          <div class="section-label">{{ $t('label.blockedUsers') }}</div>
        </div>
        <ChevronSvg />
      </div>
      <div class="link logout" @click="logout">
        <div class="group">
          <ExitSvg />
          <div class="section-label">{{ $t('label.logOut') }}</div>
        </div>
      </div>

      <div class="language-block py-6">
        <div class="label">{{ $t('label.language') }}</div>
        <LanguageSwitcher />
      </div>
    </div>
  </div>
</template>

<script>
import ShieldSvg from '@/components/icons/ShieldSvg.vue';
import CommunitySvg from '@/components/icons/CommunitySvg.vue';
import BellSvg from '@/components/icons/BellSvg.vue';
import PremiumSvg from '@/components/icons/PremiumSvg.vue';
import StarSvg from '@/components/icons/StarSvg.vue';
import BlockedSvg from '@/components/icons/BlockedSvg.vue';
import InfoSvg from '@/components/icons/InfoSvg.vue';
import ExitSvg from '@/components/icons/ExitSvg.vue';
import ChevronSvg from '@/components/icons/ChevronSvg.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { privacyPolicy } from '@/constants/urls';

export default {
  name: 'SettingsPage',
  components: {
    ShieldSvg,
    CommunitySvg,
    BellSvg,
    PremiumSvg,
    StarSvg,
    BlockedSvg,
    InfoSvg,
    ExitSvg,
    ChevronSvg,
    LanguageSwitcher,
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store.dispatch('UserStore/logout');
      this.$router.push({ name: 'login' });
    },
    changeRoute(route) {
      this.$router.push({ name: route });
    },
    openPrivacyPolicy() {
      window.open(privacyPolicy, '_blank');
    },

  },
  async mounted() {
    await this.$store.dispatch('UserStore/refreshUser');
  },
  computed: {
    isNestedRoute() {
      return this.$route.path.split('/').filter((p) => !!p).length > 1;
    },
  },
};
</script>

<style lang="scss">
.settings-page {
  height: 100%;

  .links-list {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 12px;
    margin: 0 8px;

    .language-block {
      margin-top: auto;
    }

    .disabled {
      opacity: 0.5;
    }

    .link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      border-bottom: 1px solid rgb(var(--v-theme-grey-icon));

      .group {
        display: flex;
        align-items: center;
      }

      .section-label {
        margin-inline-start: 8px;
        font-family: Exo 2;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        color: #ffffff;
      }

      svg {
        width: 24px;
        height: 24px;
        fill: white;

        circle {
          stroke: white;
          fill: none;
        }
      }

      &.logout {
        .section-label {
          color: #d63b3b;
          font-weight: 700;
        }

        svg {
          fill: #d63b3b;
        }
      }
    }
  }
}
</style>
