<template>
  <svg
    width="42"
    height="42"
    viewBox="3 3 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Age button">
      <path
        id="Vector"
        d="M24.0001 44.5716C12.657 44.5716 3.42871 35.3433 3.42871 24.0001C3.42871 12.657 12.657 3.42871 24.0001 3.42871C35.3433 3.42871 44.5716 12.657 44.5716 24.0001C44.5716 35.3433 35.3433 44.5716 24.0001 44.5716ZM24.0001 5.63267C13.8735 5.63442 5.63442 13.8735 5.63442 24.0001C5.63442 34.1268 13.8735 42.3659 24.0001 42.3659C34.1268 42.3659 42.3659 34.1268 42.3659 24.0001C42.3659 13.8735 34.1268 5.63442 24.0001 5.63442V5.63267Z"
      />
      <g id="18+">
        <path d="M13.06 19.46L10 21.04V18.9L13.46 17H15.38V31H13.06V19.46Z" />
        <path
          d="M17.9805 28.94V25.04L19.2405 23.78L18.1805 22.72V19.06L20.2405 17H26.1205L28.1805 19.06V22.72L27.1205 23.78L28.3805 25.04V28.94L26.3205 31H20.0405L17.9805 28.94ZM25.0605 22.92L25.8605 22.12V19.8L25.0605 19H21.3005L20.5005 19.8V22.12L21.3005 22.92H25.0605ZM25.2605 29L26.0605 28.2V25.66L25.2605 24.88H21.1005L20.3005 25.66V28.2L21.1005 29H25.2605Z"
        />
        <path
          d="M39.6981 26.68H36.1581V29.96H33.9181V26.68H30.3781V24.76H33.9181V21.48H36.1581V24.76H39.6981V26.68Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AgeFilterSvg',
};
</script>

<style scoped></style>
