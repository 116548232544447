function base64ToArrayBuffer(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function hexToArrayBuffer(hex) {
  const bytes = new Uint8Array(
    hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  );
  return bytes.buffer;
}

async function decryptApiKey({ encryptedKey, base64Key }) {
  const [ivHex, encryptedHex] = encryptedKey.split(':');
  const iv = hexToArrayBuffer(ivHex);
  const encryptedText = hexToArrayBuffer(encryptedHex);
  const key = base64ToArrayBuffer(base64Key);

  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    key,
    { name: 'AES-CBC' },
    false,
    ['decrypt']
  );

  const decrypted = await crypto.subtle.decrypt(
    {
      name: 'AES-CBC',
      iv: new Uint8Array(iv),
    },
    cryptoKey,
    encryptedText
  );

  const decoder = new TextDecoder();
  return decoder.decode(decrypted);
}

export default {
  decryptApiKey,
};
