<template>
  <div class="event-page" v-if="event" :class="{ 'is-keyboard-visible': isKeyboardVisible }">
    <EventTopBar :event="event" @rescheduleEvent="onReschedule" @leaveEvent="onLeaveEvent"
      @cancelEvent="onCancelEvent" />
    <div class="photo-block" v-if="!isKeyboardVisible">
      <BaseImg v-if="!!event.image" :image="event.image" alt="event.name" />
      <BaseImg v-else :image="placeholderUrl" />
    </div>
    <div class="tabs-block d-flex justify-space-around my-6">
      <div class="tab" @click="currentTab = TabsEnum.INFO" :class="{ 'is-active-tab': currentTab === TabsEnum.INFO }">
        <div>{{ $t('label.info') }}</div>
      </div>
      <div class="tab d-flex" @click="changeTab(TabsEnum.MEMBERS)"
        :class="{ 'is-active-tab': currentTab === TabsEnum.MEMBERS }">
        <v-badge v-if="isCreatedByUser && pendingList.length" :content="pendingList.length" inline color="primary">
        </v-badge>
        <div>{{ $t('label.members') }}</div>
        <div class="members-count">
          {{ confirmedMembersList.length }} / {{ event.maxPlayers }}
        </div>
      </div>
      <div class="tab" @click="changeTab(TabsEnum.CHAT)" :class="{
        'is-active-tab': currentTab === TabsEnum.CHAT,
        disabled: !isParticipating && !isCreatedByUser,
      }">
        <div>{{ $t('label.chat') }}</div>
      </div>
    </div>
    <v-window class="tab-wrapper" v-model="currentTab" :class="{ 'px-3': currentTab !== TabsEnum.CHAT }">
      <v-window-item :value="TabsEnum.INFO">
        <div class="label">{{ $t('label.game') }}</div>
        <div class="game-info">
          <GameListItem :gameId="event.gameId" :hide-actions="true" />
        </div>
        <div class="creator mt-3">
          <div class="label">{{ $t('label.creator') }}</div>
          <UserListItem v-if="creator" :item="creator" :key="event.creator" :hide-controls="true" class="pb-0" />
          <v-skeleton-loader v-else></v-skeleton-loader>
        </div>
        <div class="event-details my-3 d-flex">
          <div class="section">
            <div class="label">{{ $t('label.rules') }}</div>
            <div class="no-rules" v-if="noAdditionalRules">
              {{ $t('rules.noAdditionalRules') }}
            </div>
            <div class="rules-detailed" v-else>
              <div class="rule" v-if="event.noSmoking">
                <SmokingFilter class="me-2" />
                <div>{{ $t('rules.noSmokingAllowed') }}</div>
              </div>
              <div class="rule" v-if="event.noKids">
                <AgeFilter class="me-2" />
                <div>{{ $t('rules.pleaseAdultsOnly') }}</div>
              </div>
              <div class="rule" v-if="event.noAlcohol">
                <AlcoholFilter class="me-2" />
                <div>{{ $t('rules.noAlcohol') }}</div>
              </div>
              <div class="rule" v-if="event.noPets">
                <PetsFilter class="me-2" />
                <div>{{ $t('rules.petArentAllowed') }}</div>
              </div>
            </div>
          </div>

          <div class="section">
            <div class="label">{{ $t('label.basicInfo') }}</div>
            <div class="info-detailed">
              <div class="d-flex mb-1">
                <CalendarSvg class="me-2" />
                <div>{{ formatDate(event.date) }}</div>
              </div>
              <div class="d-flex mb-1">
                <ClockSvg class="me-2" />
                <div>
                  {{ formatTime(event.startsAt) }} -
                  {{ formatTime(event.endsAt) }}
                </div>
              </div>
              <div class="d-flex" v-if="event.price">
                <PriceSvg class="me-2" />
                <div>
                  {{ event.price }} UAH
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="label">{{ $t('label.location') }}</div>
        <div class="location d-flex mb-3">
          <div class="section d-flex">
            <LocationSvg class="me-2" />
            <div class="location-text">{{ event.locationText }}</div>
          </div>
          <div class="section show-on-map ms-2" @click="showOnMap">
            {{ $t('label.showOnMap') }}
          </div>
        </div>
        <div class="label" v-if="event.description">
          {{ $t('label.description') }}
        </div>
        <div class="description" v-if="event.description">
          {{ event.description }}
        </div>
      </v-window-item>

      <v-window-item :value="TabsEnum.MEMBERS">
        <div v-if="isCreatedByUser" class="label">
          {{ $t('label.pendingRequests') }}
        </div>
        <div v-if="isCreatedByUser" class="pending-list">
          <UserListItem v-for="player in pendingList" :key="player" :item="player" :isRequestToJoin="true"
            @rejectParticipant="rejectParticipant" @acceptParticipant="acceptParticipant" @blockedUser="loadMembers" />
          <div v-if="!pendingList.length" class="empty-placeholder">
            Seems like all requests are processed for now
          </div>
        </div>
        <div v-if="isCreatedByUser" class="label">
          {{ $t('label.confirmedUsers') }}
        </div>
        <div class="members-list" v-if="confirmedMembersList.length">
          <UserListItem v-for="(player, index) in confirmedMembersList" :key="player" :item="player"
            :class="{ 'pt-2': index !== 0 }" :remove-from-event-visible="isCreatedByUser" @blockedUser="loadMembers"
            :show-phone-allowed="isCreatedByUser" :is-call-allowed="isCreatedByUser"
            @removeParticipant="removeParticipant" />
        </div>
        <div class="empty-members pt-4" v-else>
          {{ $t('cardText.seemsLikeNoOne') }}
        </div>
      </v-window-item>

      <v-window-item :value="TabsEnum.CHAT" :disabled="!isParticipating && !isCreatedByUser">
        <EventChat />
      </v-window-item>
    </v-window>
    <InvitePopup v-if="isInvitePopupOpened" v-model="isInvitePopupOpened" :event-id="eventId" />
    <transition v-if="currentTab !== TabsEnum.CHAT">
      <div key="1" class="controls px-4 py-2" v-if="isLoaded">
        <BaseButton v-if="isCanceled" class="primary" :disabled="true">
          {{ $t('button.eventWasCancelled') }}
        </BaseButton>
        <BaseButton v-else-if="isCreatedByUser && isEnded" @click="copyEvent" class="primary">
          {{ $t('button.copyEvent') }}
        </BaseButton>
        <BaseButton v-else-if="isEnded" class="primary" :disabled="true">
          {{ $t('button.eventHasEnded') }}
        </BaseButton>
        <BaseButton v-else-if="isStarted && isCreatedByUser" @click="finishEvent" class="primary">
          {{ $t('button.finish') }}</BaseButton>
        <BaseButton v-else-if="isStarted" class="primary" :disabled="true">{{ $t('button.eventHasAlreadyStarted') }}
        </BaseButton>
        <BaseButton v-else-if="(isParticipating || isCreatedByUser) && !isEventFull" @click="invite" class="primary">
          {{ $t('button.inviteFriend') }}
        </BaseButton>
        <BaseButton v-else-if="isPending" class="primary" :disabled="true">{{ $t('button.yourRequestIsPending') }}
        </BaseButton>
        <BaseButton v-else-if="isRejected" class="primary" :disabled="true">{{ $t('button.yourRequestWasRejected') }}
        </BaseButton>
        <BaseButton v-else-if="isSubscribed && isEventFull" class="primary" :disabled="true">{{
          $t('button.waitingForFreeSpot') }}</BaseButton>
        <BaseButton v-else-if="isEventFull && !(isParticipating || isCreatedByUser)" class="primary"
          @click="subscribeToSpotNotification">{{ $t('button.notifyMeIfThereIsFreePlace') }}</BaseButton>
        <BaseButton v-else-if="isEventFull" class="primary" :disabled="true">{{
          $t('button.eventIsFull')
        }}</BaseButton>
        <BaseButton v-else @click="join" class="primary">
          {{ $t('button.join') }}</BaseButton>
      </div>
      <div key="2" v-else class="controls-placeholder"></div>
    </transition>
    <BaseDialog v-model="isDialogVisible" location="center" color="#2F313D" persistent class="pa-2 event-page-dialog">
      <v-card class="pb-8">
        <v-card-text class="pt-8 px-y">{{ dialogTitle }}</v-card-text>
        <div class="dialog-text py-4">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
    <YesNoDialog v-model="isRemoveParticipantDialogVisible"
      :dialog-text="$t('dialog.removeParticipant', { name: userToRemoveName })" @confirm="onRemoveParticipant"
      @cancel="isRemoveParticipantDialogVisible = false" />
    <RescheduleDialog v-model="isRescheduleDialogActive" :event="event" @reschedule="rescheduleHandler" />
  </div>
</template>

<script>
import EventTopBar from '@/components/EventTopBar.vue';
import GameListItem from '@/components/GameListItem.vue';
import LocationSvg from '@/components/icons/LocationSvg.vue';
import CalendarSvg from '@/components/icons/CalendarSvg.vue';
import PriceSvg from '@/components/icons/PriceSvg.vue';
import ClockSvg from '@/components/icons/ClockSvg.vue';
import SmokingFilter from '@/components/icons/SmokingFilter.vue';
import AgeFilter from '@/components/icons/AgeFilter.vue';
import AlcoholFilter from '@/components/icons/AlcoholFilter.vue';
import PetsFilter from '@/components/icons/PetsFilter.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import UserListItem from '@/components/UserListItem.vue';
import InvitePopup from '@/components/views/InvitePopup.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseImg from '@/components/base/BaseImg.vue';
import RescheduleDialog from '@/components/views/RescheduleDialog.vue';
import YesNoDialog from '@/components/views/YesNoDialog.vue';
import EventChat from '@/components/views/EventChat.vue';
import dateUtils from '@/utils/dateUtils';

const TabsEnum = {
  INFO: 'info',
  MEMBERS: 'members',
  CHAT: 'chat',
};
export default {
  name: 'EventPage',
  components: {
    BaseImg,
    EventChat,
    BaseButton,
    EventTopBar,
    GameListItem,
    InvitePopup,
    BaseDialog,
    UserListItem,
    LocationSvg,
    YesNoDialog,
    SmokingFilter,
    RescheduleDialog,
    AgeFilter,
    AlcoholFilter,
    PetsFilter,
    CalendarSvg,
    ClockSvg,
    PriceSvg
  },
  data() {
    const event = this.$store.getters['EventStore/getSelectedEvent'];
    if (!event) {
      const eventId = this.$route.params.id;
      this.$store.dispatch('EventStore/loadEvent', eventId);
    }
    return {
      TabsEnum,
      dialogTitle: '',
      isLoaded: false,
      isPending: false,
      isRejected: false,
      dialogMessage: '',
      userToRemoveId: '',
      userToRemoveName: '',
      isSubscribed: false,
      isDialogVisible: false,
      isActionInProcess: false,
      currentTab: TabsEnum.INFO,
      isInvitePopupOpened: false,
      isRescheduleDialogActive: false,
      isRemoveParticipantDialogVisible: false,
    };
  },
  async mounted() {
    this.loadMembers();
    this.checkParticipationStatus();
  },
  computed: {
    noAdditionalRules() {
      return (
        !this.event.noSmoking &&
        !this.event.noKids &&
        !this.event.noAlcohol &&
        !this.event.noPets
      );
    },
    event() {
      return this.$store.getters['EventStore/getSelectedEvent'];
    },
    eventId() {
      return this.$route.params.id;
    },
    isKeyboardVisible() {
      return this.$store.getters['SettingsStore/isKeyboardVisible'];
    },
    isParticipating() {
      const userId = this.$store.getters['UserStore/getUserId'];
      return !!this.confirmedMembersList.find((user) => user._id === userId);
    },
    isStarted() {
      return new Date(this.event.startsAt) < new Date();
    },
    isEnded() {
      return this.event.isEnded || new Date(this.event.endsAt) < new Date();
    },
    isCreatedByUser() {
      const userId = this.$store.getters['UserStore/getUserId'];
      return this.event?.creator === userId;
    },
    isEventFull() {
      return this.confirmedMembersList.length >= this.event?.maxPlayers;
    },
    isCanceled() {
      return this.event?.isCanceled;
    },
    creator() {
      return this.$store.getters['UserStore/getUserById'](this.event.creator);
    },
    userLocation() {
      return this.$store.getters['UserStore/getLocation'];
    },
    eventMembers() {
      return (
        this.$store.getters['EventStore/getEventMembers'](this.eventId) || []
      );
    },
    pendingList() {
      return this.eventMembers.length
        ? this.eventMembers.filter((member) => member.isPending)
        : [];
    },
    confirmedMembersList() {
      return this.eventMembers.length
        ? this.eventMembers.filter((member) => !member.isPending)
        : [];
    },
    placeholderUrl() {
      return require('@/assets/images/board-game-placeholder.png');
    },
  },
  methods: {
    copyEvent() {
      this.$router.push({ name: 'copyEvent', params: { eventId: this.eventId } });
    },
    changeTab(tab) {
      if (
        this.currentTab === tab ||
        !this.isLoaded ||
        (!this.isParticipating &&
          !this.isCreatedByUser &&
          tab === TabsEnum.CHAT)
      ) {
        return;
      }
      this.currentTab = tab;
    },
    showOnMap() {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${this.event.locationText}&query_place_id=${this.event.place_id}`
      );
    },
    onReschedule() {
      this.isRescheduleDialogActive = true;
    },
    rescheduleHandler(payload) {
      this.$store.dispatch('EventStore/rescheduleEvent', {
        eventId: this.eventId,
        ...payload,
      });
    },
    removeParticipant({ userId, name }) {
      if (!this.isCreatedByUser) {
        return;
      }

      this.userToRemoveId = userId;
      this.userToRemoveName = name;
      this.isRemoveParticipantDialogVisible = true;
    },
    async onRemoveParticipant() {
      await this.$store.dispatch('EventStore/removeParticipant', {
        eventId: this.eventId,
        userId: this.userToRemoveId,
      });
      this.loadMembers();
      this.userToRemoveId = '';
      this.userToRemoveName = '';
      this.isRemoveParticipantDialogVisible = false;
    },

    async onLeaveEvent() {
      await this.$store.dispatch('EventStore/leaveEvent', this.eventId);
      this.loadMembers();
      this.checkParticipationStatus();
    },
    async onCancelEvent() {
      await this.$store.dispatch('EventStore/cancelEvent', this.eventId);
    },
    formatDate(date) {
      return dateUtils.formatDate(date, this.$i18n.locale);
    },
    formatTime(isoDate) {
      return new Date(isoDate).toTimeString().substring(0, 5);
    },
    async finishEvent() {
      if (this.isActionInProcess) {
        return;
      }

      this.isActionInProcess = true;
      try {
        await this.$store.dispatch('EventStore/finishEvent', this.eventId);
        this.isActionInProcess = false;
      } catch (error) {
        console.log(error);
        this.isActionInProcess = false;
      }
    },
    join() {
      const userId = this.$store.getters['UserStore/getUserId'];
      this.$store.dispatch('EventStore/joinEvent', {
        eventId: this.eventId,
        userId,
      });
      this.isSubscribed = false;
      this.isPending = true;
    },
    invite() {
      const user = this.$store.getters['UserStore/getUser'];
      const usersToInviteList = user.following;
      this.$store.commit('UserStore/setUsersInviteList', usersToInviteList);
      this.isInvitePopupOpened = true;
    },
    showDialog({ title, message }) {
      this.dialogTitle = title;
      this.isDialogVisible = true;
      this.dialogMessage = message;
      setTimeout(() => {
        this.isDialogVisible = false;
      }, 2000);
    },
    async acceptParticipant(userId) {
      if (this.isEventFull) {
        this.showDialog({
          title: this.$t('dialog.eventIsFull'),
          message: this.$t('dialog.youCanNotAcceptMoreParticipants'),
        });
        return;
      }

      await this.$store.dispatch('EventStore/acceptParticipant', {
        eventId: this.eventId,
        userId,
      });
      this.loadMembers();
    },
    async rejectParticipant(userId) {
      await this.$store.dispatch('EventStore/rejectParticipant', {
        eventId: this.eventId,
        userId,
      });
      this.loadMembers();
    },
    async subscribeToSpotNotification() {
      await this.$store.dispatch('EventStore/subscribeToSpotNotification', {
        eventId: this.eventId,
      });
      this.isSubscribed = true;
    },
    async checkParticipationStatus() {
      const userId = this.$store.getters['UserStore/getUserId'];
      const requestStatus = await this.$store.dispatch(
        'EventStore/checkParticipationStatus',
        { eventId: this.eventId, userId }
      );
      switch (requestStatus) {
        case 'pending':
          this.isPending = true;
          break;
        case 'rejected':
          this.isRejected = true;
          break;
        case 'subscribed':
          this.isSubscribed = true;
          break;
        default:
          break;
      }
    },
    async loadMembers() {
      await this.$store.dispatch('EventStore/loadMembers', this.eventId);
      this.isLoaded = true;
    },
  },
};
</script>

<style lang="scss">
.event-page-dialog {
  text-align: center;
}

.event-page {
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .photo-block {
    display: flex;
    justify-content: center;

    img {
      width: 134px;
      height: 134px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .empty-members {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    color: rgb(var(--v-theme-grey-icon));
  }

  .photo-placeholder {
    width: 134px;
    height: 134px;
    border-radius: 50%;
    background: #23242c;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 72px;
      height: 72px;
    }
  }

  .members-count {
    color: rgb(var(--v-theme-grey-icon));
    font-size: 12px;
    margin-top: -6px;
    margin-left: 4px;
    white-space: nowrap;
  }

  .controls-placeholder {
    min-height: 56px;
  }

  .v-window {
    height: calc(100% - 56px);
    overflow-y: scroll;

    .v-window-item {
      height: 100%;
    }
  }

  .label {
    padding-bottom: 4px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgb(63, 65, 71);
  }

  .event-details {
    justify-content: space-between;

    .section {
      width: 45%;
    }

    .no-rules {
      font-weight: 200;
    }
  }

  .rule {
    display: flex;
    align-items: center;
    font-weight: 200;
    padding-bottom: 4px;

    svg {
      height: 24px;
      width: 24px;
      min-height: 24px;
      min-width: 24px;
      fill: white;
    }
  }

  .pending-list {
    .empty-placeholder {
      color: rgb(var(--v-theme-grey-icon));
      font-size: 16px;
      margin: 16px 0;
      text-align: center;
    }
  }

  .description,
  .info-detailed {
    font-weight: 200;
  }

  .info-detailed {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  .location {
    justify-content: space-between;
    align-items: center;
    font-weight: 200;

    svg {
      height: 24px;
      width: 24px;
      min-height: 24px;
      min-width: 24px;
    }

    .section svg {
      align-self: center;
    }

    .show-on-map {
      color: rgb(var(--v-theme-primary));
      font-weight: 300;
      cursor: pointer;
    }
  }

  .tab-wrapper {
    height: 100%;
    width: 100%;
  }

  .tab {
    font-size: 16px;
    line-height: 16px;
    width: 30%;
    text-align: center;

    .v-badge {
      margin-top: -2px;
    }

    &:last-of-type {
      width: 20%;
    }

    &.disabled {
      color: rgb(var(--v-theme-grey-icon));
    }
  }

  .tab:not(.is-active-tab) {
    transition: font-size 0.1s ease-out;
  }

  .tab-name {
    text-transform: capitalize;
  }

  .is-active-tab {
    color: rgb(var(--v-theme-primary));
    font-size: 20px;
    line-height: 20px;
    transition: font-size 0.3s ease-in;
  }
}
</style>
