export default {
  formatDate(date, locale) {
    const localeMap = {
      en: 'en-US',
      ua: 'uk-UA',
    };

    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    return new Date(date).toLocaleDateString(localeMap[locale], options);
  },
};
