<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.2108 0C28.3101 0 28.4094 0 28.5143 0C28.7579 2.82088 27.6094 4.92864 26.2136 6.45501C24.844 7.97084 22.9686 9.441 19.9353 9.21793C19.733 6.43745 20.8833 4.48601 22.2773 2.96316C23.57 1.54393 25.9401 0.281035 28.2108 0Z"
    />
    <path
      d="M37.3932 29.3612C37.3932 29.3893 37.3932 29.4139 37.3932 29.4402C36.5408 31.8607 35.3248 33.935 33.841 35.8601C32.4864 37.6078 30.8264 39.9597 27.8624 39.9597C25.3012 39.9597 23.6 38.4158 20.9752 38.3736C18.1986 38.3315 16.6716 39.6646 14.1329 40.0001C13.8425 40.0001 13.5521 40.0001 13.2673 40.0001C11.4032 39.7472 9.89868 38.3631 8.80264 37.116C5.57075 33.4309 3.07329 28.6709 2.60864 22.5795C2.60864 21.9823 2.60864 21.3869 2.60864 20.7897C2.80537 16.4301 5.06488 12.8856 8.06821 11.1677C9.65324 10.2544 11.8322 9.47626 14.2585 9.82404C15.2983 9.97509 16.3606 10.3088 17.2918 10.639C18.1742 10.957 19.2777 11.5208 20.3232 11.4909C21.0314 11.4716 21.7359 11.1256 22.4497 10.8814C24.5406 10.1736 26.5903 9.36209 29.2919 9.74324C32.5388 10.2034 34.8433 11.5559 36.2672 13.6426C33.5206 15.2814 31.3491 17.751 31.7201 21.9682C32.0498 25.7991 34.4255 28.0403 37.3932 29.3612Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AppleSvg',
};
</script>

<style scoped></style>
