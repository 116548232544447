<template>
  <div>
    <div class="greeting-block">
      <h1 class="enlarged-text">{{ $t('signUpFormTitles.welcome') + ' ' + $t('signUpFormTitles.friend') }}</h1>
      <h3>{{ $t('signUpFormTitles.acquainted') }}</h3>
    </div>
    <v-form ref="form" class="sign-up-block" lazy-validation>
      <BaseInput v-model="name" class="my-2" placeholder="placeholder.name" inputLabel="label.yourName" />
      <small v-if="v$.name.$error" class="error">{{
        v$ && v$.name.$errors[0].$message
      }}</small>
      <BaseInput v-model="email" class="my-2" placeholder="placeholder.email" inputLabel="label.email" />
      <small v-if="v$.email.$error" class="error">{{
        v$ && v$.email.$errors[0].$message
      }}</small>
      <BaseInput v-model="password" class="my-2" placeholder="placeholder.password" inputLabel="label.password"
        :append-inner-icon="isPasswordShown ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append-inner="isPasswordShown = !isPasswordShown" :type="isPasswordShown ? 'text' : 'password'" />
      <small v-if="v$.password.$error" class="error">{{
        v$ && v$.password.$errors[0].$message
      }}</small>

      <BaseInput v-model="confirmPassword" class="my-2" placeholder="placeholder.confirmPassword"
        inputLabel="label.confirmPassword" :append-inner-icon="isConfirmShown ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append-inner="isConfirmShown = !isConfirmShown" :type="isConfirmShown ? 'text' : 'password'" />
      <small v-if="v$.confirmPassword.$error" class="error">{{
        v$ && v$.confirmPassword.$errors[0].$message
      }}</small>
    </v-form>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  helpers,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  name: 'SignUpForm',
  components: {
    BaseInput,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      isPasswordShown: false,
      isConfirmShown: false,
    };
  },
  validations() {
    return {
      name: helpers.withMessage('Name is required.', required),
      email: {
        required: helpers.withMessage(this.$t('validation.emailRequired'), required),
        email: helpers.withMessage(this.$t('validation.emailInvalid'), email),
      },
      password: {
        required: helpers.withMessage(this.$t('validation.passwordRequired'), required),
        minLength: helpers.withMessage(
          this.$t('validation.passwordMinLength'),
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          this.$t('validation.passwordMaxLength'),
          maxLength(20)
        ),
        containsUppercase: helpers.withMessage(
          this.$t('validation.passwordContainsUppercase'),
          (value) => /[A-Z]/.test(value)
        ),
        containsLowercase: helpers.withMessage(
          this.$t('validation.passwordContainsLowercase'),
          (value) => /[a-z]/.test(value)
        ),
        containsNumber: helpers.withMessage(
          this.$t('validation.passwordContainsNumber'),
          (value) => /[0-9]/.test(value)
        ),
      },
      confirmPassword: {
        required: helpers.withMessage(
          this.$t('validation.confirmPasswordCannotBeEmpty'),
          required
        ),
        sameAsPassword: helpers.withMessage(
          this.$t('validation.confirmPasswordMustBeSame'),
          sameAs(this.password)
        ),
        $lazy: true,
      },
    };
  },
};
</script>

<style lang="scss">
.greeting-block {
  text-align: center;
}

.sign-up-block {
  margin-top: 5%;
}

.error {
  display: flex;
  color: rgb(var(--v-theme-error));
}
</style>
