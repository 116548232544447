<template>
  <div class="phone-verification-wrapper">
    <div class="security-description-block">
      <h1>{{ $t('phoneVerificationTitles.security') }}</h1>
      <h3>{{ $t('phoneVerificationTitles.securityDescriptionLine1') }}</h3>
      <h3>{{ $t('phoneVerificationTitles.securityDescriptionLine2') }}</h3>
      <h3>{{ $t('phoneVerificationTitles.securityDescriptionLine3') }}</h3>
    </div>
    <div class="phone-input">
      <BaseInput v-model="phoneNumber" class="my-2" placeholder="placeholder.phoneNumber"
        inputLabel="label.phoneNumber" />
      <BaseButton @click="confirmNumber">{{ $t('button.confirm') }}</BaseButton>
    </div>
    <div class="code-confirmation-block" v-if="isConfirmationSent">
      <h4 class="code-usage-description">
        {{ $t('phoneVerificationTitles.codeUsageDescription') }}
      </h4>
      <div class="code-input">
        <BaseInput v-model="digit1" type="tel" :data-index="1" class="code-digit" maxlength="1" @input="setNext" />
        <BaseInput v-model="digit2" type="tel" :data-index="2" class="code-digit" maxlength="1" @input="setNext" />
        <BaseInput v-model="digit3" type="tel" :data-index="3" class="code-digit" maxlength="1" @input="setNext" />
        <BaseInput v-model="digit4" type="tel" :data-index="4" class="code-digit" maxlength="1" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
export default {
  name: 'PhoneVerification',
  components: {
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      phoneNumber: '',
      isConfirmationSent: false,
      digit1: '',
      digit2: '',
      digit3: '',
      digit4: '',
    };
  },
  methods: {
    confirmNumber() {
      this.isConfirmationSent = true;
    },
    setNext(event) {
      let wrapper = event.srcElement;

      while (!wrapper || !wrapper.getAttribute('data-index')) {
        wrapper = wrapper.parentElement;
      }

      if (!wrapper) {
        return;
      }

      if (wrapper.nextSibling) {
        const nextInput = wrapper.nextSibling.querySelector('input');
        nextInput.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.phone-verification-wrapper {
  .phone-input {
    margin-top: 52px;
  }
}

.security-description-block {
  text-align: center;
}

.code-confirmation-block {
  margin-top: 16px;

  .code-input {
    margin: 8px 72px;
    display: flex;
    justify-content: space-around;

    .v-text-field .v-field,
    .v-input {
      max-width: 48px;
      height: 48px;

      input {
        padding: 0;
        max-height: 48px;
        min-height: 48px;
        width: 48px;
        line-height: 48px;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }
    }

    // .code-digit {
    //   color: #000000;
    // }
  }
}
</style>
