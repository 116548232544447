<template>
  <div class="notifications-settings px-3">
    <div class="settings-block" v-if="areSettingsLoaded">
      <div class="settings-item d-flex">
        <span class="my-auto">{{ $t('label.pushNotificationsAllowed') }}</span>
        <BaseSwitch v-model="pushNotificationsAllowed" @change="onSettingChange" :disabled="isChangeInProcess" />
      </div>
      <div class="notifications-description">
        <p>{{ $t('instructions.pushNotificationsDescription') }}</p>
      </div>
    </div>
    <div class="loader" v-else>
      <v-skeleton-loader v-for="i in 10" :key="i" color="background" :height="150" type="list-item-three-line" />
    </div>
  </div>
</template>

<script>
import BaseSwitch from "@/components/base/BaseSwitch.vue";
export default {
  components: {
    BaseSwitch,
  },
  data() {
    return {
      areSettingsLoaded: false,
      isChangeInProcess: false,
      pushNotificationsAllowed: true,
    };
  },
  async mounted() {
    await this.$store.dispatch('SettingsStore/loadNotificationsSettings');

    this.pushNotificationsAllowed = this.notificationsSettings?.pushNotificationsAllowed ?? true;
    this.areSettingsLoaded = true;
  },
  computed: {
    notificationsSettings() {
      return this.$store.getters['SettingsStore/getNotificationsSettings'];
    },
  },
  methods: {
    async onSettingChange() {
      if (this.isChangeInProcess) {
        return;
      }

      this.isChangeInProcess = true;

      await this.$store.dispatch('SettingsStore/updateNotificationsSettings', {
        pushNotificationsAllowed: this.pushNotificationsAllowed,
      });

      this.isChangeInProcess = false;

      if (this.pushNotificationsAllowed && window.FirebasePlugin) {
        let hasPermission = await new Promise((resolve) => {
          window.FirebasePlugin.hasPermission(() => {
            resolve(true);
          }, () => {
            resolve(false);
          });

          if (!hasPermission) {
            window.FirebasePlugin.grantPermission();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.notifications-settings {

  .settings-item {
    width: 100%;
    justify-content: space-between;
  }

  .notifications-description {
    color: #8e8e8e;
    font-weight: 400;
  }
}
</style>
