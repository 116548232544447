import ServerRequest from '@/utils/ServerRequest';
import { getCountryDataList, getEmojiFlag } from 'countries-list';

const excludedCountries = ['Russia', 'Belarus'];
let countriesList = getCountryDataList().filter(
  (c) => !excludedCountries.includes(c.name)
);

countriesList.map((c) => {
  c.emoji = getEmojiFlag(c.iso2);
  return c;
});

const state = () => {
  return {
    initialUsers: [],
    initialEvents: [],
    initialGames: [],
    events: [],
    users: [],
    games: [],
    noResults: false,
    searchState: null,
    countriesList,
    gameDetailsMap: new Map(),
    // searchResults: [],
  };
};

const mutations = {
  setEvents(state, events) {
    state.events = events;
    if (state.initialEvents.length === 0) {
      state.initialEvents = events;
    }
  },
  setGames(state, games) {
    state.games = games;
    if (state.initialGames.length === 0) {
      state.initialGames = games;
    }
  },
  setUsers(state, users) {
    state.users = users;
    if (state.initialUsers.length === 0) {
      state.initialUsers = users;
    }
  },
  setNoResults(state, value) {
    state.noResults = value;
  },
  setSearchState(state, searchState) {
    state.searchState = searchState;
  },

  setGameDetails(state, { gameId, gameDetails }) {
    state.gameDetailsMap.set(gameId, gameDetails);
  },
};

const getters = {
  getInitialUsers: (state) => state.initialUsers,
  getInitialEvents: (state) => state.initialEvents,
  getInitialGames: (state) => state.initialGames,
  getEvents: (state) => state.events,
  getGames: (state) => state.games,
  getGameDetails: (state) => (gameId) =>
    state.gameDetailsMap.get(gameId) || null,
  getUsers: (state) => state.users,
  getNoResults: (state) => state.noResults,
  getSearchState: (state) => state.searchState,
  getCountriesList: (state) => state.countriesList,
  getCountryName: () => (code) => {
    const country = countriesList.find((c) => c.key === code);
    return country ? country.name : '';
  },
  getEmojiFlag: () => (code) => getEmojiFlag(code),
  getCountryCode: () => (name) => {
    const country = countriesList.find((c) => c.name === name);
    return country ? country.key : '';
  },
};

const actions = {
  async search({ commit, rootGetters, getters }, { category, query, page }) {
    try {
      const coordinates = rootGetters['UserStore/getCoordinates'] || {};
      const userId = rootGetters['UserStore/getUserId'];

      if (!page || page === 1) {
        switch (category) {
          case 'users':
            commit('setUsers', []);
            break;
          case 'events':
            commit('setEvents', []);
            break;
          case 'games':
            commit('setGames', []);
            break;
          default:
            break;
        }
      }

      const response = await ServerRequest.get('search', 'searchItems', {
        category,
        query,
        userId,
        page,
        lat: coordinates.lat,
        lng: coordinates.lng,
      });

      const items = response[category] || [];

      commit('setNoResults', items.length === 0);

      const currentUserId = rootGetters['UserStore/getUser']?._id || '0';

      switch (category) {
        case 'users':
          let users = getters.getUsers;
          users = users.concat(
            items.filter(
              (user) =>
                user._id !== currentUserId &&
                !users.find((u) => u._id === user._id)
            )
          );
          commit('setUsers', users);
          break;
        case 'events':
          commit('setEvents', items);
          break;
        case 'games': {
          for (const item of items) {
            commit('setGameDetails', {
              gameId: item.id,
              gameDetails: item,
            });
          }
          const gameIds = items.map((game) => game.id);
          commit('setGames', gameIds);
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  },

  async saveSearchState({ commit }, searchState) {
    commit('setSearchState', searchState);
  },
  async initialSearch({ dispatch }) {
    try {
      dispatch('search', { category: 'games', query: '' });
      dispatch('search', { category: 'users', query: '' });
      await dispatch('search', { category: 'events', query: '' });
    } catch (error) {
      console.error(error);
    }
  },
  restoreInitialResults({ commit, state }) {
    commit('setEvents', state.initialEvents);
    commit('setGames', state.initialGames);
    commit('setUsers', state.initialUsers);
  },
  removeUserFromSearch({ commit, state }, userId) {
    commit(
      'setUsers',
      state.users.filter((user) => user._id !== userId)
    );
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
