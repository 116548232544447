import { createStore } from 'vuex';

import GameStore from './GameStore';
import UserStore from './UserStore';
import EventStore from './EventStore';
import SearchStore from './SearchStore';
import SettingsStore from './SettingsStore';
import TabStore from './TabStore';


// Create a new store instance.
const store = createStore({ modules: { UserStore, EventStore, SearchStore, GameStore, SettingsStore, TabStore } });

export default store;
