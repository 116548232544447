<template>
  <div class="confirm-phone">
    <div class="confirm-label pt-4 pb-2">{{ $t('label.confirmPhoneNumber') }}</div>
    <div class="instruction-text pb-4">{{ $t('instructions.confirmPhoneExplanation') }}</div>
    <div class="phone-input" v-if="(!isPhoneVerified || isChangeNumber) && !isCodeSent">
      <v-select v-model="selectedCountry" :items="countriesList" :item-props="true" item-title="fullCode"
        item-value="phone" :label="$t('label.countryCodeSelect')" variant="outlined" hide-details
        class="country-select"></v-select>
      <BaseInput type="tel" v-model="phone" :inputLabel="$t('label.phoneInput')" />
      <BaseButton class="confirm-button mt-2" @click="sendCode(phone)">
        {{ $t('button.sendCodeToPhone') }}
      </BaseButton>
    </div>
    <div class="code-input" v-else-if="(!isPhoneVerified || isChangeNumber) && isCodeSent">
      <BaseInput v-model="phone" :inputLabel="$t('label.phoneInput')" :disabled="true" />
      <div class="otp-wrapper">
        <div class="code-label">{{ $t('label.confirmationCode') }}</div>
        <v-otp-input v-model="otp" autocomplete="one-time-code" :num-inputs="6" />
        <BaseButton class="confirm-button mt-2" @click="onComplete">
          {{ $t('button.confirm') }}
        </BaseButton>
      </div>
    </div>
    <div class="phone-verified" v-else-if="isPhoneVerified">
      <BaseInput v-model="phone" :inputLabel="$t('label.phoneInput')" :disabled="true" />
      <BaseButton class="change-number mt-2" @click="changeNumber">
        {{ $t('button.changeNumber') }}
      </BaseButton>
    </div>
    <BaseDialog v-model="isDialogVisible" location="center" class="pa-2">
      <v-card class="pa-8">
        <div class="dialog-text">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';

export default {
  name: 'ConfirmPhone',
  components: {
    BaseButton,
    BaseInput,
    BaseDialog,
  },
  data() {
    const userCountry = this.$store.getters['UserStore/getCountry'];
    let countriesList = JSON.parse(JSON.stringify(this.$store.getters['SearchStore/getCountriesList']));
    countriesList = countriesList.map((country) => {
      return {
        name: country.name,
        phone: country.phone[0],
        native: country.native,
        fullCode: `+${country.phone[0]} - ${country.native}`,
        flag: country.emoji,
      };
    });

    countriesList.sort((a, b) => a.phone - b.phone);
    const selectedCountry = countriesList.find((country) => country.name === userCountry || country.native === userCountry) || countriesList[0];
    const phone = this.$store.getters['UserStore/getUser'].phone || `+${selectedCountry.phone}`;
    return {
      dialogMessage: '',
      isChangeNumber: false,
      isDialogVisible: false,
      isCodeSending: false,
      isCodeSent: false,
      isCodeChecking: false,
      isCodeChecked: false,
      countriesList,
      selectedCountry,
      phone,
      currentCode: selectedCountry.phone,
      otp: '',
    };
  },
  watch: {
    selectedCountry(val) {
      this.phone = `+${val}`;
    },
  },
  computed: {
    isPhoneVerified() {
      return this.$store.getters['UserStore/getIsPhoneVerified'];
    }
  },
  methods: {
    changeNumber() {
      this.isCodeSent = false;
      this.isCodeChecked = false;
      this.isChangeNumber = true;
      this.otp = '';
    },
    async onComplete() {
      if (this.otp.length < 6 || this.isCodeChecking) {
        return;
      }

      this.isCodeChecking = true;
      try {
        const isChecked = await this.$store.dispatch('UserStore/checkVerificationCode', {
          phone: this.phone,
          code: this.otp,
        });
        this.isCodeChecking = false;
        this.isCodeChecked = isChecked;

        if (isChecked) {
          this.showDialog(this.$t('dialog.phoneConfirmed'));
        }
        else {
          this.showDialog(this.$t('dialog.invalidCode'));
        }

      } catch (err) {
        this.isCodeChecked = false;
        this.showDialog(this.$t('dialog.somethingWentWrong'));
        console.log(err);
      } finally {
        this.isCodeChecking = false;
      }
    },
    async sendCode() {
      const isPhoneValidRE = /\+[0-9]{1,3}?[-. \]?([0-9]{1,4}[-. \]?){1,4}[0-9]/;
      if (!isPhoneValidRE.test(this.phone)) {
        this.showDialog(this.$t('dialog.invalidPhone'));
        return;
      }

      this.isCodeSending = true;

      try {
        const isSent = await this.$store.dispatch('UserStore/verifyPhone', {
          phone: this.phone,
        });

        if (isSent) {
          this.isCodeSent = true;
        }
        else {
          this.showDialog(this.$t('dialog.somethingWentWrong'));
        }
      }
      catch (error) {
        this.showDialog(this.$t('dialog.somethingWentWrong'));
        console.log(error);
      }
      finally {
        this.isCodeSending = false;
      }
    },
    phoneInputHandler(e) {
      if (e.target.value.length === 0) {
        this.phone = `+${this.currentCode}`;
      }
    },
    showDialog(message) {
      this.dialogMessage = message;
      this.isDialogVisible = true;
    },
    hideDialog() {
      this.isDialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
.confirm-phone {
  .instruction-text {
    color: #8e8e8e;
    font-weight: 500;
  }
}
</style>