<template>
    <div class="base-textarea-wrapper">
      <span class="base-textarea-label">{{ inputLabel ? $t(inputLabel) : '' }}</span>
      <v-textarea v-bind="$attrs" autocapitalize="none" variant="outlined" class="base-textarea" hide-details
        :placeholder="placeholder ? $t(placeholder) : ''">
        <template v-if="$attrs.prependInner" v-slot:prepend-inner>
          <v-icon :icon="$attrs.prependInner" />
        </template>
      </v-textarea>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BaseTextarea',
    props: {
      inputLabel: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    inheritAttrs: false,
    data() {
      return {};
    },
  };
  </script>
  
  <style lang="scss">
  .base-textarea-wrapper {
    .v-textarea .v-field,
    .v-input {
      border-radius: 8px;
  
      textarea {
        padding: 10px 12px;
        line-height: 1.5em;
      }
    }
  
    .v-textarea {
      min-height: 100px;
    }
  }
  </style>

  