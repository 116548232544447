<template>
  <div class="notification-page">
    <div class="notification-tabs mb-2 d-flex">
      <div class="tab" :class="{ 'is-active-tab': currentTab === EVENTS_TAB }" @click="changeTab(EVENTS_TAB)">
        {{ $t('notification.events') }}
      </div>
      <div class="tab" :class="{ 'is-active-tab': currentTab === OTHERS_TAB }" @click="changeTab(OTHERS_TAB)">
        {{ $t('notification.socialsAndOthers') }}
      </div>
    </div>

    <v-window v-model="currentTab" class="notifications-list" v-if="!isLoading && notifications.length">
      <v-window-item :value="EVENTS_TAB" class="window-item">
        <div class="today-block" v-if="todayEventNotifications.length">
          <div class="label">{{ $t('notification.today') }}</div>
          <NotificationItem v-for="item in todayEventNotifications" :key="item.nid" :notification="item" />
        </div>
        <div class="yesterday-block" v-if="yesterdayEventNotifications.length">
          <div class="label">{{ $t('notification.yesterday') }}</div>
          <NotificationItem v-for="item in yesterdayEventNotifications" :key="item.nid" :notification="item" />
        </div>
        <div class="older-block" v-if="olderEventNotifications.length">
          <div class="label">{{ $t('notification.older') }}</div>
          <NotificationItem v-for="item in olderEventNotifications" :key="item.nid" :notification="item" />
        </div>
        <div class="empty-list" v-if="noEventNotifications">
          <v-icon>mdi-bell-off</v-icon>
          <div>{{ $t('notification.noEventNotificationsMessage') }}</div>
        </div>
      </v-window-item>
      <v-window-item :value="OTHERS_TAB" class="window-item">
        <div class="today-block" v-if="todayOtherNotifications.length">
          <div class="label">{{ $t('notification.today') }}</div>
          <NotificationItem v-for="item in todayOtherNotifications" :key="item.nid" :notification="item" />
        </div>
        <div class="yesterday-block" v-if="yesterdayOtherNotifications.length">
          <div class="label">{{ $t('notification.yesterday') }}</div>
          <NotificationItem v-for="item in yesterdayOtherNotifications" :key="item.nid" :notification="item" />
        </div>
        <div class="older-block" v-if="olderOtherNotifications.length">
          <div class="label">{{ $t('notification.older') }}</div>
          <NotificationItem v-for="item in olderOtherNotifications" :key="item.nid" :notification="item" />
        </div>
        <div class="empty-list" v-if="noOtherNotifications">
          <v-icon>mdi-bell-off</v-icon>
          <div>{{ $t('notification.noOtherNotificationsMessage') }}</div>
        </div>
      </v-window-item>
    </v-window>
    <div class="skeleton-loader" v-else-if="isLoading">
      <v-skeleton-loader v-for="i in 10" :key="i" color="background" :height="150" type="list-item-avatar-three-line" />
    </div>
    <div class="empty-list" v-else>
      <v-icon>mdi-bell-off</v-icon>
      <div>{{ $t('notification.noNotificationsMessage') }}</div>
    </div>
  </div>
</template>

<script>
import NotificationItem from '@/components/NotificationItem.vue';

export default {
  name: 'NotificationsPage',
  data() {
    const lastOpenedTab =
      this.$store.getters['SettingsStore/getNotificationsPageTab'];
    const startOfToday = new Date();
    startOfToday.setMilliseconds(0);
    startOfToday.setSeconds(0);
    startOfToday.setMinutes(0);
    startOfToday.setHours(0);

    const startOfYesterday = new Date(
      startOfToday.getTime() - 24 * 60 * 60 * 1000
    );
    const EVENTS_TAB = 'events';
    const OTHERS_TAB = 'others';

    const tabs = [EVENTS_TAB, OTHERS_TAB];

    const currentTab = lastOpenedTab
      ? tabs.includes(lastOpenedTab)
        ? lastOpenedTab
        : tabs[0]
      : tabs[0];

    return {
      tabs,
      EVENTS_TAB,
      OTHERS_TAB,
      currentTab,
      startOfToday,
      startOfYesterday,
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('UserStore/fetchNotifications');
    if (this.isIOS) {
      window.FirebasePlugin.setBadgeNumber(0);
    }
    this.isLoading = false;
  },
  beforeUnmount() {
    this.$store.commit(
      'SettingsStore/setNotificationsPageTab',
      this.currentTab
    );
  },
  components: {
    NotificationItem,
  },
  computed: {
    isIOS() {
      return window?.device?.platform === 'iOS';
    },
    notifications() {
      return this.$store.getters['UserStore/getNotifications'];
    },
    eventNotifications() {
      return this.notifications.filter((n) => n.data.type === 'event');
    },
    otherNotifications() {
      return this.notifications.filter((n) => n.data.type !== 'event');
    },
    todayEventNotifications() {
      return this.eventNotifications.filter(
        (n) => new Date(n.sentAt) > this.startOfToday
      );
    },
    noEventNotifications() {
      return this.eventNotifications.length === 0;
    },
    noOtherNotifications() {
      return this.otherNotifications.length === 0;
    },
    yesterdayEventNotifications() {
      return this.eventNotifications.filter(
        (n) =>
          new Date(n.sentAt) > this.startOfYesterday &&
          new Date(n.sentAt) <= this.startOfToday
      );
    },
    olderEventNotifications() {
      return this.eventNotifications.filter(
        (n) => new Date(n.sentAt) <= this.startOfYesterday
      );
    },
    todayOtherNotifications() {
      return this.otherNotifications.filter(
        (n) => new Date(n.sentAt) > this.startOfToday
      );
    },
    yesterdayOtherNotifications() {
      return this.otherNotifications.filter(
        (n) =>
          new Date(n.sentAt) > this.startOfYesterday &&
          new Date(n.sentAt) <= this.startOfToday
      );
    },
    olderOtherNotifications() {
      return this.otherNotifications.filter(
        (n) => new Date(n.sentAt) <= this.startOfYesterday
      );
    },
  },
  methods: {
    changeTab(tab) {
      if (this.currentTab === tab) {
        return;
      }

      this.currentTab = tab;
    },
  },
};
</script>

<style lang="scss">
.notification-page {
  height: 100%;
  overflow-y: hidden;

  .notification-tabs {
    justify-content: space-around;
  }

  .window-item {
    height: 100%;
    // overflow-y: auto;
  }

  .notifications-list {
    height: calc(100% - 32px);
  }

  .v-window__container {
    height: 100%;
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    i {
      font-size: 48px;
      color: rgb(var(--v-theme-grey-icon));
    }

    div {
      font-size: 18px;
      color: rgb(var(--v-theme-grey-icon));
      text-align: center;
    }
  }

  .window-item {
    overflow-y: scroll;
    padding: 0 12px;
  }

  .label {
    padding: 8px 0;
    border-bottom: 1px solid rgb(63, 65, 71);
    margin-bottom: 8px;
  }

  .tab {
    height: 24px;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }

  .tab:not(.is-active-tab) {
    transition: font-size 0.3s ease-out;
  }

  .is-active-tab {
    color: rgb(var(--v-theme-primary));
    font-size: 20px;
    line-height: 20px;
    transition: font-size 0.3s ease-in;
  }
}
</style>
