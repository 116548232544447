<template>
  <div class="base-input-wrapper">
    <span class="base-input-label">{{ inputLabel ? $t(inputLabel) : '' }}</span>
    <v-text-field v-bind="$attrs" variant="outlined" class="base-input" hide-details
      :placeholder="placeholder ? $t(placeholder) : ''">
      <template v-if="$attrs.prependInner" v-slot:prepend-inner>
        <v-icon :icon="$attrs.prependInner" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    inputLabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  inheritAttrs: false,
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.base-input-wrapper {

  .v-text-field .v-field,
  .v-input {
    height: 44px;
    border-radius: 8px;

    input {
      padding: 10px 12px;
      max-height: 44px;
      min-height: 44px;
      line-height: 44px;
    }
  }
}
</style>
