<template>
  <div class="event-top-bar">
    <div class="section -left">
      <v-icon
        class="back-button"
        @click="goBack"
        icon="mdi-arrow-left"
      ></v-icon>
    </div>
    <div class="section -middle">
      <BaseImg
        v-show="isKeyboardVisible && !!event.image"
        :image="event.image"
        class="event-image me-2"
        alt="event.name"
      />
      <BaseImg
        v-show="isKeyboardVisible && !event.image"
        :image="placeholderUrl"
        class="event-image me-2"
      />
      <div class="title">{{ event.name }}</div>
    </div>
    <div class="section -right">
      <ShareSvg class="me-4" @click="shareEvent" id="eventShareBtn" />
      <BottomMenu :menu-items="menuItems" :empty="event.isCanceled" />
    </div>
    <YesNoDialog
      v-model="isConfirmationDialogActive"
      :dialog-text="dialogText"
      @confirm="onConfirm"
      @cancel="onCancel"
      :is-cancel-red="isCancelRed"
      :is-confirm-red="isConfirmRed"
    />
    <QrDialog v-model="isQrDialogActive" :qr-code="qrCode" />
    <ReportDialog
      v-model="isReportDialogActive"
      :name="event.name"
      :event-id="event._id"
      report-type="event"
    />
  </div>
</template>

<script>
import BottomMenu from '@/components/BottomMenu.vue';
import ReportDialog from '@/components/views/ReportDialog.vue';
import QrDialog from '@/components/views/QrDialog.vue';
import ShareSvg from '@/components/icons/ShareSvg.vue';
import YesNoDialog from '@/components/views/YesNoDialog.vue';
import QrCodeUtils from '@/utils/QrCodeUtils';
import BaseImg from '@/components/base/BaseImg.vue';
import SharingUtils from '@/utils/SharingUtils';
import { landing, webApp } from '@/constants/urls';

export default {
  name: 'EventTopBar',
  components: {
    BaseImg,
    ShareSvg,
    BottomMenu,
    ReportDialog,
    YesNoDialog,
    QrDialog,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isConfirmationDialogActive: false,
      isReportDialogActive: false,
      isQrDialogActive: false,
      dialogText: '',
      qrCode: '',
      isConfirmRed: false,
      isCancelRed: false,
      confirmHandler: () => {},
      cancelHandler: () => {},
    };
  },

  computed: {
    currentUserId() {
      return this.$store.getters['UserStore/getUserId'];
    },
    eventMembers() {
      return (
        this.$store.getters['EventStore/getEventMembers'](this.event._id) || []
      );
    },
    linkBase() {
      return this.isMobile ? landing : webApp;
    },
    linkRoute() {
      return this.isMobile ? 'eventId' : 'event';
    },
    isMobile() {
      return !!window.cordova;
    },
    isKeyboardVisible() {
      return this.$store.getters['SettingsStore/isKeyboardVisible'];
    },
    placeholderUrl() {
      return require('@/assets/images/board-game-placeholder.png');
    },
    pendingList() {
      return this.eventMembers.length
        ? this.eventMembers.filter((member) => member.isPending)
        : [];
    },
    confirmedMembersList() {
      return this.eventMembers.length
        ? this.eventMembers.filter((member) => !member.isPending)
        : [];
    },
    isUserParticipating() {
      return this.confirmedMembersList.some(
        (member) => member._id === this.currentUserId
      );
    },
    isCanceled() {
      return this.event.isCanceled;
    },
    menuItems() {
      const menuItems = [
        {
          title: this.$t('menuItem.rescheduleEvent'),
          icon: 'mdi-calendar-edit',
          color: '',
          handler: () => {
            this.$emit('rescheduleEvent');
          },
          isVisible:
            this.currentUserId === this.event.creator &&
            !this.event.isStarted &&
            !this.isCanceled,
        },
        {
          title: this.$t('menuItem.qenerateQR'),
          icon: 'mdi-qrcode',
          color: '',
          handler: async () => {
            let url = `${this.linkBase}/${this.linkRoute}/${this.event._id}`;
            this.qrCode = await QrCodeUtils.generateQrCode(url);
            this.isQrDialogActive = true;
          },
          isVisible: !this.isCanceled && !this.event.isStarted,
        },
        {
          title: this.$t('menuItem.leaveEvent'),
          icon: 'ExitSvg',
          isCustomIcon: true,
          color: '#D63B3B',
          handler: () => {
            this.isConfirmationDialogActive = true;
            this.dialogText = this.$t('dialog.leaveEvent');
            this.isConfirmRed = true;
            this.confirmHandler = this.confirmLeave;
            this.cancelHandler = this.cancelLeave;
          },
          isVisible:
            !this.isCanceled &&
            this.currentUserId !== this.event.creator &&
            this.isUserParticipating &&
            !this.event.isStarted,
        },
        {
          title: this.$t('menuItem.report'),
          icon: 'mdi-flag-outline',
          color: '#D63B3B',
          handler: () => {
            this.isReportDialogActive = true;
          },
          isVisible:
            !this.isCanceled && this.currentUserId !== this.event.creator,
        },
        {
          title: this.$t('menuItem.cancelEvent'),
          icon: 'OutlinedCrossSvg',
          isCustomIcon: true,
          color: '#D63B3B',
          handler: () => {
            this.isConfirmationDialogActive = true;
            this.dialogText = this.$t('dialog.cancelEvent');
            this.isConfirmRed = true;
            this.confirmHandler = this.confirmEventCancel;
            this.cancelHandler = this.cancelEventCancellation;
          },
          isVisible:
            !this.isCanceled &&
            this.currentUserId === this.event.creator &&
            !this.event.isStarted,
        },
      ];
      return menuItems.filter((item) => item.isVisible);
    },
  },
  methods: {
    goBack() {
      const prevTab = this.$store.state.TabStore.previousTab || '#games';
      if (this.checkPreviousRoute("/profile#games") || this.checkPreviousRoute("/profile") || this.checkPreviousRoute("/profile#info")) {
        this.$router.push(`/profile${prevTab}`);
      } else {
        this.$router.back();
      }
    },
    checkPreviousRoute(route) {
      return this.$router.options.history.state.back === route;
    },

    onConfirm() {
      this.confirmHandler();
    },
    onCancel() {
      this.cancelHandler();
    },
    resetHandlers() {
      this.isConfirmationDialogActive = false;
      this.isCancelRed = false;
      this.isConfirmRed = false;
      this.confirmHandler = () => {};
      this.cancelHandler = () => {};
    },
    confirmLeave() {
      this.$emit('leaveEvent');
      this.resetHandlers();
    },
    cancelLeave() {
      this.resetHandlers();
    },
    confirmEventCancel() {
      this.$emit('cancelEvent');
      this.resetHandlers();
    },
    cancelEventCancellation() {
      this.resetHandlers();
    },
    shareEvent() {
      const shareBtn = document.getElementById('eventShareBtn');
      shareBtn.classList.toggle('animated');

      shareBtn.addEventListener('animationend', () => {
        shareBtn.classList.remove('animated');
      });

      const url = `${this.linkBase}/${this.linkRoute}/${this.event._id}`;
      SharingUtils.share({
        title: this.$t('menuItem.shareEventURL'),
        text: this.event.name,
        url,
      });
    },
  },
};
</script>

<style lang="scss">
.event-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  min-height: calc(70px + env(safe-area-inset-top));
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;

  .event-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .animated {
    animation: button-reaction 0.5s;
  }

  @keyframes button-reaction {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  .section {
    display: flex;
    align-items: center;

    &.-left {
      flex: 1;
    }

    &.-middle {
      flex: 2;
      max-width: 50%;
      justify-content: center;

      .title {
        font-family: Exo 2;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: calc(100%);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &.-right {
      flex: 1;
      justify-content: flex-end;

      i {
        transform: rotate(-90deg);
      }
    }
  }

  @keyframes running-text {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(-160%, 0);
    }
  }

  i {
    height: 20px;
    width: 20px;
    color: #a7a7a7;
  }
}
</style>
