<template>
  <BaseDialog v-model="isReportDialogVisible" location="center" color="#2F313D">
    <v-card class="px-8 pb-8 pt-4">
      <div class="text-center pb-4">{{ $t('label.reportProfile', { name }) }}</div>
      <v-textarea v-model="reportReason" hide-details rows="4" class="message-input" variant="outlined"
        :placeholder="$t('placeholder.reportReason')" density="compact" ref="messageInput" auto-grow />
      <BaseButton class="primary mt-4" @click="reportProfile">
        {{ $t('button.report') }}
      </BaseButton>
    </v-card>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'ReportDialog',
  components: {
    BaseDialog,
    BaseButton,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
    eventId: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    reportType: {
      type: String,
      default: 'user',
    }
  },
  data() {
    return {
      isReportDialogVisible: this.modelValue,
      reportReason: '',
    };
  },
  watch: {
    modelValue(value) {
      this.isReportDialogVisible = value;
    },
    isReportDialogVisible(value) {
      this.$emit('update:modelValue', value);
    },
  },
  methods: {
    async reportProfile() {
      switch (this.reportType) {
        case 'user':
          await this.$store.dispatch('UserStore/reportProfile', {
            userId: this.userId,
            reason: this.reportReason,
          });
          break;
        case 'event':
          await this.$store.dispatch('EventStore/reportEvent', {
            eventId: this.eventId,
            reason: this.reportReason,
          });
          break;
      }
      this.isReportDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>