// store/modules/TabStore.js

const state = () => ({
  previousTab: '#games',
});

const mutations = {
  setPreviousTab(state, hash) {
    state.previousTab = hash;
  },
};

const actions = {
  updatePreviousTab({ commit }, hash) {
    commit('setPreviousTab', hash);
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
