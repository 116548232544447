<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.2666 12C4.2666 7.58172 7.84832 4 12.2666 4C14.047 4 15.6915 4.58159 17.0206 5.56515L5.83175 16.754C4.84819 15.4249 4.2666 13.7804 4.2666 12ZM7.21255 18.2017C8.59033 19.3258 10.3497 20 12.2666 20C16.6849 20 20.2666 16.4183 20.2666 12C20.2666 10.0831 19.5924 8.32372 18.4683 6.94595L7.21255 18.2017ZM12.2666 2C6.74375 2 2.2666 6.47715 2.2666 12C2.2666 17.5228 6.74375 22 12.2666 22C17.7894 22 22.2666 17.5228 22.2666 12C22.2666 6.47715 17.7894 2 12.2666 2Z" />
  </svg>

</template>

<script>
export default {
  name: 'BlockedSvg',
};
</script>
