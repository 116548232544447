import ServerRequest from '@/utils/ServerRequest';

const state = () => {
  return {
    notificationsPageTab: 'events',
    usersListPageTitle: 'Users',
    isKeyboardVisible: false,
    notificationsSettings: null,
  };
};

const mutations = {
  setNotificationsPageTab(state, tab) {
    state.notificationsPageTab = tab;
  },
  setUsersListPageTitle(state, title) {
    state.usersListPageTitle = title;
  },
  setKeyboardVisible(state, isOpen) {
    state.isKeyboardVisible = isOpen;
  },
  setNotificationsSettings(state, settings) {
    state.notificationsSettings = settings;
  },
};

const getters = {
  getNotificationsPageTab: (state) => {
    return state.notificationsPageTab;
  },
  getUsersListPageTitle: (state) => {
    return state.usersListPageTitle;
  },
  getNotificationsSettings: (state) => {
    return state.notificationsSettings;
  },
  isKeyboardVisible: (state) => {
    return state.isKeyboardVisible;
  },
};

const actions = {
  async loadNotificationsSettings({ rootGetters, commit }) {
    try {
      const st = rootGetters['UserStore/getUser'].sessionToken;
      const result = await ServerRequest.get(
        'users',
        'getNotificationsSettings',
        { st }
      );

      commit('setNotificationsSettings', result.notificationsSettings);
    } catch (error) {
      console.log(error);
    }
  },

  async updateNotificationsSettings({ rootGetters, commit }, settings) {
    try {
      const st = rootGetters['UserStore/getUser'].sessionToken;
      const result = await ServerRequest.post(
        'users',
        'updateNotificationsSettings',
        { st, settings }
      );

      commit('setNotificationsSettings', result.notificationsSettings);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
