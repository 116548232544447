<template>
  <div class="reset-password-page px-3">
    <header>
      <div class="icon-wrapper" v-if="isBackButtonVisible">
        <v-icon @click="toPreviousPage" icon="mdi-arrow-left" />
      </div>
      <div class="icon-placeholder" v-else></div>
      <SmallLogoBlockVue />
      <div class="empty-placeholder"></div>
    </header>
    <div class="reset-text-block">
      <div class="reset-title">{{ $t('passwordRecoveryTitles.passwordRecovery') }}</div>
      <div class="reset-text pt-2" v-if="showEmailInput">
        {{ $t('passwordRecoveryTitles.passwordRecoveryDescriptionLine1') }}
      </div>
      <div class="reset-text pt-2" v-else-if="showCodeInput">
        {{ $t('passwordRecoveryTitles.passwordRecoveryDescriptionLine2') }}
      </div>
      <div class="reset-text pt-2" v-else-if="showResetPasswordInputs">
        {{ $t('passwordRecoveryTitles.passwordRecoveryDescriptionLine3') }}
      </div>
    </div>

    <div class="reset-controls-form" v-if="showEmailInput">
      <BaseInput v-model="email" class="my-2" placeholder="placeholder.email" inputLabel="label.email" />
      <small v-if="v$.email.$error" class="error pb-1">{{
        v$ && v$.email.$errors[0].$message
      }}</small>
      <BaseButton class="primary" @click="confirmEmail">Send code</BaseButton>
    </div>
    <div class="code-block" v-else-if="showCodeInput">
      <v-otp-input v-model="codeInputValue"></v-otp-input>
      <BaseButton class="primary" @click="confirmReset">Confirm</BaseButton>
    </div>
    <ResetPasswordForm v-else-if="showResetPasswordInputs" :email="email" :code="codeInputValue" />
    <BaseDialog v-model="isDialogVisible" location="center" color="#2F313D" persistent
      class="pa-2 reset-password-page-dialog">
      <v-card class="pa-8">
        <div class="dialog-text social">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import SmallLogoBlockVue from '@/components/SmallLogoBlock.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import ResetPasswordForm from '@/components/views/ResetPasswordForm.vue';
import {
  required,
  email,
  sameAs,
  helpers,
  minLength,
  maxLength,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
export default {
  name: 'ResetPasswordPage',
  components: {
    SmallLogoBlockVue,
    ResetPasswordForm,
    BaseDialog,
    BaseInput,
    BaseButton,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: '',
      password: '',
      dialogMessage: '',
      codeInputValue: '',
      confirmPassword: '',
      showEmailInput: true,
      showCodeInput: false,
      isConfirmShown: false,
      isDialogVisible: false,
      isPasswordShown: false,
      showResetPasswordInputs: false,
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage(this.$t('validation.emailRequired'), required),
        email: helpers.withMessage(this.$t('validation.emailInvalid'), email),
      },
    };
  },
  computed: {
    isBackButtonVisible() {
      return this.showEmailInput;
    },
  },
  methods: {
    toPreviousPage() {
      this.$router.go(-1);
    },
    showDialog(message) {
      this.dialogMessage = message;
      this.isDialogVisible = true;
      setTimeout(() => {
        this.isDialogVisible = false;
      }, 1000);
    },
    async confirmEmail() {
      this.v$.email.$touch();
      if (this.v$.$errors.length) {
        window.console.log('error', this.v$.$errors);
        return;
      }

      const result = await this.$store.dispatch(
        'UserStore/createResetPasswordTask',
        { email: this.email }
      );
      if (result.isCreated) {
        this.showEmailInput = false;
        this.showCodeInput = true;
      } else {
        this.showDialog(this.$t('dialog.userWithEmailNotFound'));
      }
    },

    async confirmReset() {
      if (!this.codeInputValue) {
        this.showDialog(this.$t('dialog.pleaseEnterCode'));
        return;
      }

      const result = await this.$store.dispatch(
        'UserStore/checkResetConfirmationCode',
        {
          email: this.email,
          code: this.codeInputValue,
        }
      );

      if (result.isConfirmed) {
        this.showCodeInput = false;
        this.showResetPasswordInputs = true;
      } else {
        this.showDialog(this.$t('dialog.invalidCodePleaseTryAgain'));
      }
    },
  },
};
</script>

<style lang="scss">
.reset-password-page {
  flex-direction: column;
  gap: 40px;
  display: flex;

  .icon-placeholder {
    width: 24px;
    height: 24px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: env(safe-area-inset-top);

    .icon-wrapper {
      margin-top: -20px;
    }

    i {
      color: rgb(var(--v-theme-grey-icon));
    }

    .small-logo-block {
      margin-bottom: 0;
    }

    .empty-placeholder {
      width: 24px;
      height: 24px;
    }
  }

  .reset-title {
    font-family: Exo 2;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  .reset-text {
    font-family: Exo 2;
    font-size: 18px;
    font-weight: 300;
    line-height: 28.8px;
    text-align: center;
  }
}
</style>
