// Purpose: Class for creating Event objects
class Event {
  constructor(creatorId) {
    let hours = new Date().getHours().toString();
    let minutes = new Date().getMinutes().toString();

    if (hours.length === 1) {
      hours = `0${hours}`;
    }

    if (minutes.length === 1) {
      minutes = `0${minutes}`;
    }
    const currentTime = `${hours}:${minutes}`;

    this.name = '';
    this.gameName = '';
    this.description = '';
    this.date = new Date();
    this.startsAt = currentTime;
    this.endsAt = currentTime;
    this.locationText = '';
    this.place_id = '';
    this.minPlayers = 2;
    this.maxPlayers = 2;
    this.playTimeRange = '';
    this.players = [];
    this.creator = creatorId;
    this.gameId = null;
    this.image = null;

    this.noPets = false;
    this.noAlcohol = false;
    this.noSmoking = false;
    this.noKids = false;

    this.creatorParticipates = true;
    this.inviteOnly = false;
    this.publicPlace = true;
    this.buySomething = false;
    this.paidEvent = false;
    this.verifiedUsersOnly = false;

    this.price = 0;
  }
}

export default Event;
