import ServerRequest from '@/utils/ServerRequest';

export default {
  methods: {
    async toggleFavoriteStatus(gameId) {
      try {
        const userId = this.$store.getters['UserStore/getUserId'];
        const response = await ServerRequest.post('users', 'toggleFavoriteGame', {
          gameId,
          userId,
        });
        this.$store.commit('UserStore/setFavoriteGames', response.favoriteGames);
      } catch (error) {
        console.error(error);
      }
    }
  },
  computed: {
    isGameFavorite() {
      return (gameId) => this.$store.getters['UserStore/isGameFavorite'](gameId);
    }
  }
}