<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.3335 4.66634C1.3335 2.82539 2.82588 1.33301 4.66683 1.33301H11.3335C13.1744 1.33301 14.6668 2.82539 14.6668 4.66634V11.333C14.6668 13.174 13.1744 14.6663 11.3335 14.6663H4.66683C2.82588 14.6663 1.3335 13.174 1.3335 11.333V4.66634ZM4.66683 2.66634C3.56226 2.66634 2.66683 3.56177 2.66683 4.66634V11.333C2.66683 12.4376 3.56226 13.333 4.66683 13.333H11.3335C12.4381 13.333 13.3335 12.4376 13.3335 11.333V4.66634C13.3335 3.56177 12.4381 2.66634 11.3335 2.66634H4.66683Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.3335 7.99967C7.3335 6.52692 8.5274 5.33301 10.0002 5.33301H13.3335C14.0699 5.33301 14.6668 5.92996 14.6668 6.66634V9.33301C14.6668 10.0694 14.0699 10.6663 13.3335 10.6663H10.0002C8.5274 10.6663 7.3335 9.47243 7.3335 7.99967ZM10.0002 6.66634C9.26378 6.66634 8.66683 7.26329 8.66683 7.99967C8.66683 8.73605 9.26378 9.33301 10.0002 9.33301H13.3335V6.66634H10.0002Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9.3335 7.99967C9.3335 7.63148 9.63197 7.33301 10.0002 7.33301L10.0668 7.33301C10.435 7.33301 10.7335 7.63148 10.7335 7.99967C10.7335 8.36786 10.435 8.66634 10.0668 8.66634L10.0002 8.66634C9.63197 8.66634 9.3335 8.36786 9.3335 7.99967Z"
            fill="white" />
    </svg>

</template>

<script>
export default {
    name: 'PriceSvg',
};
</script>
