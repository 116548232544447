<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00008 2.66659C6.95042 2.66659 5.93649 3.10491 5.18343 3.89708C4.42929 4.6904 4.00008 5.77326 4.00008 6.90901C4.00008 8.5626 5.02429 10.1767 6.16213 11.4401C6.71934 12.0589 7.27856 12.5656 7.69901 12.9178C7.81035 13.0111 7.9116 13.0932 8.00008 13.1634C8.08856 13.0932 8.18981 13.0111 8.30115 12.9178C8.7216 12.5656 9.28082 12.0589 9.83803 11.4401C10.9759 10.1767 12.0001 8.5626 12.0001 6.90901C12.0001 5.77326 11.5709 4.6904 10.8167 3.89708C10.0637 3.10491 9.04974 2.66659 8.00008 2.66659ZM8.00008 13.9999C7.6173 14.5457 7.61713 14.5456 7.61695 14.5455L7.61527 14.5443L7.61155 14.5417L7.59913 14.5329C7.58867 14.5254 7.5739 14.5148 7.5551 14.5011C7.51751 14.4737 7.4638 14.434 7.39626 14.3827C7.26124 14.2801 7.07059 14.1307 6.84282 13.94C6.38826 13.5592 5.78082 13.0092 5.17136 12.3324C3.97587 11.005 2.66675 9.0736 2.66675 6.90901C2.66675 5.44081 3.22087 4.02638 4.21707 2.97843C5.21435 1.92934 6.57439 1.33325 8.00008 1.33325C9.42578 1.33325 10.7858 1.92934 11.7831 2.97843C12.7793 4.02638 13.3334 5.44081 13.3334 6.90901C13.3334 9.0736 12.0243 11.005 10.8288 12.3324C10.2193 13.0092 9.6119 13.5592 9.15734 13.94C8.92957 14.1307 8.73892 14.2801 8.6039 14.3827C8.53636 14.434 8.48265 14.4737 8.44506 14.5011C8.42627 14.5148 8.41149 14.5254 8.40104 14.5329L8.38861 14.5417L8.38489 14.5443L8.38366 14.5452C8.38348 14.5453 8.38286 14.5457 8.00008 13.9999ZM8.00008 13.9999L8.38286 14.5457C8.15311 14.7069 7.84671 14.7066 7.61695 14.5455L8.00008 13.9999Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00016 5.33333C7.26378 5.33333 6.66683 5.93029 6.66683 6.66667C6.66683 7.40305 7.26378 8 8.00016 8C8.73654 8 9.3335 7.40305 9.3335 6.66667C9.3335 5.93029 8.73654 5.33333 8.00016 5.33333ZM5.3335 6.66667C5.3335 5.19391 6.5274 4 8.00016 4C9.47292 4 10.6668 5.19391 10.6668 6.66667C10.6668 8.13943 9.47292 9.33333 8.00016 9.33333C6.5274 9.33333 5.3335 8.13943 5.3335 6.66667Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'LocationSvg',
};
</script>
