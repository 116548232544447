import { createRouter, createWebHashHistory } from 'vue-router';
import LoginPage from '@/pages/LoginPage';
import EventPage from '@/pages/EventPage';
import SearchPage from '@/pages/SearchPage';
import SignUpPage from '@/pages/SignUpPage';
import ProfilePage from '@/pages/ProfilePage';
import SettingsPage from '@/pages/SettingsPage';
import SelectGamePage from '@/pages/SelectGamePage';
import CreateEventPage from '@/pages/CreateEventPage';
import CopyEventPage from '@/pages/CopyEventPage';
import UsersListPage from '@/pages/UsersListPage.vue';
import EditProfile from '@/components/views/EditProfile';
import AboutRating from '@/components/views/AboutRating';
import NotificationsPage from '@/pages/NotificationsPage';
import BlockedUsers from '@/components/views/BlockedUsers';
import PrivacyPolicy from '@/components/views/PrivacyPolicy';
import SocialSettings from '@/components/views/SocialSettings';
import PremiumSetting from '@/components/views/PremiumSetting';
import SecuritySetting from '@/components/views/SecuritySetting';
import MyInvitedMembers from '@/components/views/MyInvitedMembers';
import ResetPasswordPage from '@/components/ResetPasswordPage.vue';
import NotificationsSetting from '@/components/views/NotificationsSetting';
import GameDetailsPage from '@/pages/GameDetailsPage.vue';
import EventsArchivePage from '@/pages/EventsArchivePage.vue';

const routes = [
  { path: '/', redirect: '/login' },
  { name: 'login', path: '/login', component: LoginPage },
  { name: 'signUp', path: '/signUp', component: SignUpPage },
  {
    path: '/settings',
    children: [
      { name: 'settings', path: '', component: SettingsPage },
      { name: 'socialSettings', path: 'social', component: SocialSettings },
      { name: 'aboutRating', path: 'aboutRating', component: AboutRating },
      { name: 'premiumSettings', path: 'premium', component: PremiumSetting },
      { name: 'blockedUsers', path: 'blockedUsers', component: BlockedUsers },
      {
        name: 'securitySettings',
        path: 'security',
        component: SecuritySetting,
      },
      {
        name: 'privacyPolicy',
        path: 'privacyPolicy',
        component: PrivacyPolicy,
      },
      {
        name: 'myInvitedMembers',
        path: 'myInvitedMembers',
        component: MyInvitedMembers,
      },
      {
        name: 'notificationsSettings',
        path: 'notifications',
        component: NotificationsSetting,
      },
    ],
  },
  {
    name: 'resetPassword',
    path: '/resetPassword',
    component: ResetPasswordPage,
  },
  { name: 'gameInfo', path: '/game/:id', component: GameDetailsPage },
  { name: 'search', path: '/search', component: SearchPage },
  { name: 'event', path: '/event/:id', component: EventPage },
  {
    path: '/createEvent',
    children: [
      { name: 'createEvent', path: '', component: CreateEventPage },
      { name: 'copyEvent', path: '/copy/:id', component: CopyEventPage },
      { name: 'selectGame', path: 'selectGame', component: SelectGamePage },
    ],
  },
  {
    name: 'notifications',
    path: '/notifications',
    component: NotificationsPage,
  },
  { name: 'profile', path: '/profile', component: ProfilePage },
  { name: 'profileId', path: '/profile/:id', component: ProfilePage },
  { name: 'usersList', path: '/usersList', component: UsersListPage },
  {
    name: 'eventsArchive',
    path: '/eventsArchive/:id',
    component: EventsArchivePage,
  },
  { name: 'editProfile', path: '/editProfile', component: EditProfile },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const startPages = ['login', 'signUp', 'resetPassword'];
  const isOnStartPage = startPages.includes(to.name);
  const shouldOpenIdentityForm = localStorage.getItem('shouldOpenIdentityForm');
  const loggedIn = localStorage.getItem('user');

  const isAppStarted = !from.name && to.name === 'login';
  const isOauthRedirect = to.fullPath.includes('/state=profile');
  const queryParams = new URLSearchParams(to.fullPath);
  const accessToken = queryParams.get('access_token');

  if (isOauthRedirect) {
    localStorage.setItem('accessToken', accessToken);
  }

  if (!loggedIn && !isOnStartPage) {
    return next('/login');
  } else if (loggedIn && isOnStartPage && !shouldOpenIdentityForm) {
    return next('/search');
  } else if (isAppStarted && shouldOpenIdentityForm) {
    return next('/signUp');
  } else {
    return next();
  }
});

export default router;
