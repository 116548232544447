<template>
  <div class="events-list-page">
    <div class="empty-list" v-if="!isLoading && !events.length">
      <v-icon>calendar-blank-outline</v-icon>
      <div>{{ $t('cardText.noEventsInArchive') }}</div>
    </div>
    <div class="events-list" v-else-if="!isLoading">
      <EventListItem v-for="event in events" :key="event._id" :item="event" class="pt-3" />
    </div>
    <div class="skeleton-loader" v-else-if="isLoading">
      <v-skeleton-loader v-for="i in 10" :key="i" color="background" :height="150" type="list-item-avatar-three-line" />
    </div>
  </div>
</template>

<script>
import EventListItem from '@/components/EventListItem.vue';

export default {
  name: 'EventsArchivePage',
  components: {
    EventListItem,
  },
  data() {
    return {
      isLoading: false,
      events: [],
    };
  },
  async mounted() {
    const userId = this.$route.params.id;
    const archiveCached = this.$store.getters["EventStore/getEventArchive"](userId);

    if (archiveCached.length) {
      this.events = archiveCached;
      return;
    }

    try {
      this.events = await this.$store.dispatch('EventStore/fetchArchiveEventsList', userId);
    }
    catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.events-list-page {
  height: 100%;
  padding: 0 12px;

  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #9e9e9e;
    font-size: 20px;
    font-weight: 500;
    gap: 10px;

    .v-icon {
      font-size: 50px;
    }
  }
}
</style>
