<template>
  <v-bottom-sheet v-if="!!menuItems.length" class="bottom-menu" :scrim="true" v-model="isSheetActive">
    <template v-slot:activator="{ props }">
      <v-icon icon="mdi-dots-vertical" v-bind="props" />
    </template>
    <v-list class="px-8 py-4">
      <div class="separator" @click="isSheetActive = false"></div>
      <v-list-item v-for="(item, i) in menuItems" @click="handleItemClick(item)" :key="i">
        <v-list-item-title class="menu-item">
          <component v-if="item.isCustomIcon" :is="item.icon" class="menu-item-icon" :style="{ fill: item.color }"
            :color="item.color" />
          <v-icon v-else class="menu-item-icon" :icon="item.icon" :color="item.color" />
          <span class="menu-item-text" :style="{ color: item.color }">{{
            item.title
          }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-bottom-sheet>
  <v-bottom-sheet v-else class="bottom-menu" :scrim="true" v-model="isSheetActive">
    <template v-slot:activator="{ props }">
      <v-icon icon="mdi-dots-vertical" v-bind="props" />
    </template>
    <v-card class="px-8 pb-14 empty-list">
      <div class="separator mb-14" @click="isSheetActive = false"></div>
      <v-card-text class="text-center">{{ $t('cardText.noActionsAvailable') }}</v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import ExitSvg from '@/components/icons/ExitSvg.vue';
import OutlinedCrossSvg from '@/components/icons/OutlinedCrossSvg.vue';

export default {
  name: 'BottomMenu',
  components: {
    ExitSvg,
    OutlinedCrossSvg,
  },
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSheetActive: false,
    };
  },
  methods: {
    async handleItemClick(item) {
      await item.handler();
      this.isSheetActive = false;
    },
  },
};
</script>

<style lang="scss">
.bottom-menu {

  .v-list,
  .empty-list {
    padding-top: 20px;
    border-radius: 30px 30px 0 0 !important;
  }

  &.v-bottom-sheet>.v-bottom-sheet__content.v-overlay__content {
    border-radius: 20px;
  }

  .v-overlay__scrim {
    background: transparent;
  }
}

.v-bottom-sheet {

  .v-list,
  .v-list-item {
    background-color: rgb(var(--v-theme-background)) !important;
  }
}

.v-overlay--active.bottom-menu {
  backdrop-filter: blur(2px);
}
</style>
