import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import i18n from './i18n';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { en, uk } from 'vuetify/locale';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';

const myCustomDarkTheme = {
  dark: true,
  colors: {
    background: '#2F313D',
    surface: '#2F313D',
    primary: '#a594fd',
    secondary: '#2F313D',
    error: '#e57373',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'msg-bg': '#313448',
    'my-msg-bg': '#4F5577',
    'grey-icon': '#A7A7A7',
    'surface-bright': '#C3C3C3',
    'darken-bg': '#222430',
  },
};

const vuetify = createVuetify({
  configFile: './main.scss',
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  locale: {
    locale: 'uk',
    fallback: 'en',
    messages: { uk, en },
  },
  theme: {
    defaultTheme: 'myCustomDarkTheme',
    themes: {
      myCustomDarkTheme,
    },
  },
});

createApp(App).use(router).use(store).use(vuetify).use(i18n).mount('#app');
