<template>
  <div class="message-wrapper" :class="{ own: isOwnMessage }">
    <div class="avatar" v-if="!isOwnMessage">
      <img v-if="user.avatar" :src="user.avatar" alt="avatar" />
      <v-icon v-else>mdi-account-circle</v-icon>
    </div>
    <div class="content">
      <div class="username" v-if="!isOwnMessage">{{ user.name }}</div>
      <div class="message-text">{{ message.message }}</div>
      <div class="timestamp">{{ gmtToTime(message.timestamp) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatMessage',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    gmtToTime(gmt) {
      const date = new Date(gmt);
      return date.toLocaleTimeString().substring(0, 5);
    },
  },
  computed: {
    currentUserId() {
      return this.$store.getters['UserStore/getUserId'];
    },
    isOwnMessage() {
      return this.message.senderId === this.currentUserId;
    },
    user() {
      return (
        this.$store.getters['UserStore/getUserById'](this.message.senderId) ||
        {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.message-wrapper {
  display: flex;
  width: 70%;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 0px 16px 16px 16px;
    background-color: rgb(var(--v-theme-msg-bg));
    word-wrap: break-word;

    .username {
      font-size: 0.8rem;
      color: rgb(var(--v-theme-primary));
      font-weight: 500;
    }
  }

  .timestamp {
    font-size: 0.6rem;
    color: rgb(var(--v-theme-grey-icon));
    align-self: flex-end;
  }

  .avatar img,
  .avatar i {
    margin-top: 4px;
    font-size: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &.own {
    align-self: flex-end;

    .content {
      border-radius: 16px 0px 16px 16px;
      background-color: rgb(var(--v-theme-my-msg-bg));
    }
  }
}
</style>
