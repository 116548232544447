<template>
  <div class="users-list-page">
    <div class="empty-list" v-if="!isLoading && !users.length">
      <v-icon>mdi-account-off</v-icon>
      <div>{{ $t('cardText.noUsersFound') }}</div>
    </div>
    <div class="users-list" v-else-if="!isLoading">
      <UserListItem v-for="user in users" :key="user._id" :item="user" class="pt-3" />
    </div>
    <div class="skeleton-loader" v-else-if="isLoading">
      <v-skeleton-loader v-for="i in 10" :key="i" color="background" :height="150" type="list-item-avatar-three-line" />
    </div>
  </div>
</template>

<script>
import UserListItem from '@/components/UserListItem.vue';

export default {
  name: 'UsersListPage',
  components: {
    UserListItem,
  },
  data() {
    return {
      isLoading: false,
      users: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    const usersList = this.$store.getters['UserStore/getUsersPageList'];
    this.users = await this.$store.dispatch(
      'UserStore/fetchUsersList',
      usersList
    );
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.users-list-page {
  height: 100%;
  padding: 0 12px;

  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #9e9e9e;
    font-size: 20px;
    font-weight: 500;
    gap: 10px;

    .v-icon {
      font-size: 50px;
    }
  }
}
</style>
