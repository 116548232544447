<template>
  <div class="notification-item d-flex my-4" @click="openNotification">
    <div class="image-block">
      <div class="event-image" v-if="isEventNotification">
        <img
          v-if="notification.data.eventImage"
          class="main-img"
          :src="notification.data.eventImage"
          alt="event image"
        />
        <img
          v-else
          class="main-img"
          :src="eventPlaceholderImage"
          alt="event image"
        />
        <img
          v-if="notification.data.userImage"
          class="secondary-img"
          :src="notification.data.userImage"
          alt="user"
        />
      </div>
      <div class="follower-image" v-if="isFollowerNotification">
        <img
          v-if="notification.data.userImage"
          class="main-img"
          :src="notification.data.userImage"
          alt="user"
        />
        <v-icon v-else icon="mdi-account" class="photo-placeholder" />
      </div>
    </div>
    <div class="notification-body px-4">
      <div class="notification-text">
        <span class="bold">{{ notification.body }}</span>
      </div>
      <div class="notification-time">
        {{ getPassedTime(notification.sentAt) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationItem',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getPassedTime(sentAt) {
      const now = new Date();
      const sent = new Date(sentAt);
      const diff = now - sent;
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      if (days > 0) {
        return `${days} d ago`;
      }
      if (hours > 0) {
        return `${hours} h ago`;
      }
      if (minutes > 0) {
        return `${minutes} m ago`;
      }
      return `${seconds} s ago`;
    },
    openNotification() {
      if (this.isEventNotification) {
        this.$store.commit('EventStore/setEventData', null);
        this.$router.push({
          name: 'event',
          params: { id: this.notification.data.eventId },
        });
      } else if (this.isFollowerNotification) {
        this.$router.push({
          name: 'profileId',
          params: { id: this.notification.data.followerId },
        });
      }
    },
  },
  computed: {
    eventPlaceholderImage() {
      return require('@/assets/images/board-game-placeholder.png');
    },
    isEventNotification() {
      return this.notification?.data?.type === 'event';
    },
    isFollowerNotification() {
      return this.notification?.data?.type === 'follower';
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-item {
  .main-img,
  .photo-placeholder {
    width: 72px;
    height: 72px;
    object-fit: cover;
    border-radius: 16px;
  }

  .photo-placeholder {
    background: #23242c;
  }

  .image-block {
    max-height: 80px;
    max-width: 72px;
  }

  .secondary-img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    position: relative;
    left: 40px;
    bottom: 32px;
  }

  .notification-time {
    font-size: 12px;
    color: #a0a3bd;
  }
}
</style>
