<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.715 7.19627C11.298 6.22755 12.7024 6.22754 13.2854 7.19627L14.2844 8.85618L16.1717 9.29329C17.2732 9.54839 17.7072 10.8841 16.966 11.7379L15.6961 13.2009L15.8636 15.131C15.9613 16.2573 14.8251 17.0829 13.7841 16.6418L12.0002 15.8861L10.2164 16.6418C9.17534 17.0829 8.03912 16.2573 8.13688 15.131L8.30438 13.2009L7.03441 11.7379C6.29325 10.8841 6.72724 9.54839 7.82872 9.29329L9.71607 8.85618L10.715 7.19627ZM12.0002 8.9394L11.3205 10.0689C11.111 10.417 10.7694 10.6652 10.3737 10.7568L9.08938 11.0543L9.95357 12.0498C10.2198 12.3565 10.3503 12.7581 10.3152 13.1628L10.2012 14.4762L11.4151 13.9619C11.7891 13.8035 12.2114 13.8035 12.5854 13.9619L13.7992 14.4762L13.6852 13.1628C13.6501 12.7581 13.7806 12.3565 14.0469 12.0498L14.9111 11.0543L13.6268 10.7568C13.2311 10.6652 12.8894 10.417 12.68 10.0689L12.0002 8.9394Z" />
  </svg>

</template>

<script>
export default {
  name: 'PremiumSvg',
};
</script>
