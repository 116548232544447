<template>
  <div class="game-top-bar">
    <div class="section -left">
      <v-icon
        class="back-button"
        @click="goBack"
        icon="mdi-arrow-left"
      ></v-icon>
    </div>
    <div class="section -middle">
      <div class="title">{{ gameName }}</div>
    </div>
    <div class="section -right">
      <ShareSvg class="me-4" @click="shareEvent" />
      <BottomMenu :menu-items="menuItems" />
    </div>
  </div>
</template>

<script>
import ShareSvg from '@/components/icons/ShareSvg.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import SharingUtils from '@/utils/SharingUtils';
import { landing, webApp } from '@/constants/urls';

export default {
  name: 'GameItemTopBar',
  props: {
    game: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ShareSvg,
    BottomMenu,
  },
  computed: {
    gameName() {
      return this.$i18n.locale === 'ua'
        ? this.game.ukrainianName || this.game.name
        : this.game.name;
    },
    menuItems() {
      const menuItems = [
        {
          title: this.$t('menuItem.createEventWithGame'),
          icon: 'mdi-dice-6-outline',
          color: '',
          handler: this.createEventWithGame,
          isVisible: true,
        },
        {
          title: this.$t('menuItem.openRules'),
          icon: 'mdi-open-in-new',
          color: '',
          handler: this.openBGGRules,
          isVisible: true,
        },
      ];
      return menuItems.filter((item) => item.isVisible);
    },
    isMobile() {
      return !!window.cordova;
    },
    linkBase() {
      return this.isMobile ? landing : webApp;
    },
    linkRoute() {
      return this.isMobile ? 'gameId' : 'game';
    },
  },
  methods: {
    goBack() {
      const prevTab = this.$store.state.TabStore.previousTab || '#/profile#games';
      if (this.checkPreviousRoute("/profile#events") || this.checkPreviousRoute("/profile#info")) {
        this.$router.push(`/profile${prevTab}`);
      } else {
        this.$router.back();
      }
    },
    checkPreviousRoute(route) {
      return this.$router.options.history.state.back === route;
    },
    shareEvent() {
      const url = `${this.linkBase}/${this.linkRoute}/${this.game.id}`;
      SharingUtils.share({
        title: this.game.name,
        text: this.$t('menuItem.checkOutGame', { gameName: this.game.name }),
        url,
      });
    },
    openBGGRules() {
      window.open(
        `https://boardgamegeek.com/boardgame/${this.$route.params.id}`
      );
    },
    createEventWithGame() {
      this.$store.commit('EventStore/setSelectedGame', this.game);
      this.$router.push({ name: 'createEvent' });
    },
  },
};
</script>

<style lang="scss">
.game-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(70px + env(safe-area-inset-top));
  z-index: 1;

  .section {
    display: flex;
    align-items: center;

    &.-left {
      flex: 1;
    }

    &.-middle {
      flex: 2;
      max-width: 50%;
      justify-content: center;

      .title {
        font-family: Exo 2;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: calc(100%);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &.-right {
      flex: 1;
      justify-content: flex-end;

      i {
        transform: rotate(-90deg);
      }
    }
  }

  @keyframes running-text {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(-160%, 0);
    }
  }

  i {
    height: 20px;
    width: 20px;
    color: #a7a7a7;
  }
}
</style>
