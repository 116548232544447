<template>
  <div class="photo-block d-flex justify-space-around">
    <div class="photo-wrapper d-flex">
      <image-viewer :visible="!!imageFile && imageViewerActive" :image-url="image" @close="imageViewerActive = false" />
      <img class="photo" v-if="!!imageFile" :src="imageFile" alt="" @click="viewFullScreenImage" />
      <v-icon v-else-if="canUpload" icon="mdi-camera" class="photo-placeholder" />
      <v-icon v-else icon="mdi-account" class="photo-placeholder account" />
    </div>
    <input v-if="canUpload" type="file" @change="onPhotoUpload" id="imageUploadInput" name="image_uploads"
      accept="image/png, image/jpeg" />
    <BaseDialog v-model="isDialogVisible" location="center" color="#2F313D" persistent body-class="dark-overlay"
      class="pa-2 photo-dialog">
      <v-card class="pa-8">
        <div class="dialog-text social">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import ServerRequest from '@/utils/ServerRequest';
import BaseDialog from '@/components/base/BaseDialog.vue';
import ImageViewer from './ImageViewer.vue';
export default {
  name: 'PhotoBlock',
  components: {
    ImageViewer,
    BaseDialog,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PHOTO_SIZE_LIMIT: 10 * 1024 * 1024,
      imageFile: this.image,
      imageViewerActive: false,
      isDialogVisible: false,
    };
  },
  methods: {
    showDialog(message) {
      this.dialogMessage = message;
      this.isDialogVisible = true;
    },
    clearDialog() {
      this.dialogMessage = '';
      this.isDialogVisible = false;
    },
    viewFullScreenImage() {
      this.imageViewerActive = true;
    },
    async onPhotoUpload(event) {
      try {
        const file = event.target.files[0];

        if (file.size > this.PHOTO_SIZE_LIMIT) {
          event.target.value = '';
          this.showDialog(this.$t('dialog.fileSizeShould'));
          setTimeout(() => {
            this.clearDialog();
          }, 2000);
          return;
        }

        this.$emit('upload-started')

        const base64 = await this.convertBase64(file);
        this.imageFile = base64;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', this.$store.getters['UserStore/getUserId']);
        const serverUrl = ServerRequest.getServerUrl();

        const resp = await fetch(`${serverUrl}/files/uploadImage`, {
          method: 'POST',
          body: formData,
        });

        const data = await resp.json();
        const imageUrl = data.imageUrl;
        this.imageFile = imageUrl;
        this.$emit('photo-uploaded', this.imageFile);
      } catch (error) {
        console.log(error);
      }
    },
    convertBase64(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },
  },
};
</script>

<style lang="scss">
.photo-dialog {
  .v-overlay__scrim {
    background: black;
    opacity: 0.5;
  }
}

.photo-block {
  .photo-wrapper {
    height: 112px;
    width: 112px;
    border-radius: 50%;
    background: #23242c;

    justify-content: center;
    align-items: center;
  }

  i.photo-placeholder.account {
    font-size: 48px;
  }

  .photo {
    height: 112px;
    width: 112px;
    border-radius: 50%;
    object-fit: cover;
  }

  #imageUploadInput {
    opacity: 0;
    position: absolute;
    height: 112px;
    width: 112px;
  }
}
</style>
