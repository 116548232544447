<template>
  <BaseDialog
    v-model="isActive"
    class="image-viewer-dialog"
    body-class="dark-overlay"
  >
    <v-img
      class="image-viewer"
      :src="imageUrl"
      max-width="100%"
      max-height="100%"
      @touchstart="touchStart"
      :id="id"
      @touchmove.prevent="handleSwipe"
      @touchend="touchEnd"
    ></v-img>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog.vue';
export default {
  name: 'ImageViewer',
  components: {
    BaseDialog,
  },
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const id = `image-${Math.random().toString(36).substr(2, 9)}`;
    return {
      id,
      isActive: this.visible,
      isMovingImageAllowed: false,
      touchStartY: 0,
      touchEndY: 0,
    };
  },
  watch: {
    visible(value) {
      this.isActive = value;
    },

    isActive(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    touchStart(e) {
      e.preventDefault();
      this.isMovingImageAllowed = true;

      const image = document.getElementById(this.id);
      this.touchStartY = e.touches[0].screenY;
      image.style.transition = 'none';
    },
    touchEnd(e) {
      this.touchEndY = e.changedTouches[0].screenY;

      const deltaY = this.touchEndY - this.touchStartY;
      const image = document.getElementById(this.id);
      const swipeThreshold = image.getBoundingClientRect().height / 2; // Minimum distance for a swipe
      if (Math.abs(deltaY) > swipeThreshold) {
        this.isActive = false;
      } else {
        image.style.transition = 'transform 0.3s ease';
        image.style.transform = 'translate(0, 0)';
      }
      this.isMovingImageAllowed = false;
    },
    handleSwipe(e) {
      if (!this.isMovingImageAllowed) {
        return;
      }

      const touchMoveY = e.changedTouches[0].screenY;
      const deltaY = touchMoveY - this.touchStartY;
      const image = document.getElementById(this.id);
      image.style.transform = `translate(0px, ${deltaY}px)`;
    },
  },
};
</script>

<style lang="scss">
.image-viewer-dialog {
  .v-overlay__scrim {
    background: black;
    opacity: 0.5;
  }
}

body.dark-overlay {
  background-color: #18181f;
}
</style>
